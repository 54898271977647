<template class="app-swipe-tutorial">
  <div class="swipe-tips__wrapper" if.bind="show">
    <svg
      class="swipe-tips__hand"
      width="28"
      height="42"
      viewBox="0 0 28 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="swipe-tips__dot"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.96758 20.2442C1.55215 18.1259 0.0285645 15.0235 0.0285645 11.5696C0.0285645 5.18422 5.23591 0 11.6502 0C18.0644 0 23.2719 5.18422 23.2719 11.5696C23.2719 13.963 22.5403 16.1873 21.2881 18.0328C20.382 17.6417 19.3635 17.4805 18.3761 17.5925C17.939 17.3709 17.4757 17.2051 16.997 17.0992V14.8812C16.997 12.0542 14.6481 9.70435 11.7971 9.70435C8.97841 9.70435 6.597 12.0751 6.597 14.8812V20.2431C6.13593 20.1527 5.66705 20.1074 5.19705 20.1078C4.76944 20.1078 4.35782 20.1522 3.96758 20.2442Z"
        fill="white"
        fill-opacity="0.39"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.00714 19.9521C4.38655 19.866 4.78571 19.8242 5.20008 19.8242C5.66992 19.8239 6.13886 19.8693 6.60004 19.9597V14.5977C6.60004 11.7917 8.98134 9.42102 11.8 9.42102C14.651 9.42102 17 11.7708 17 14.5977V16.8156C17.4787 16.9217 17.942 17.0874 18.379 17.3091C19.3664 17.1971 20.3849 17.3583 21.2911 17.7493L21.3247 17.7634C21.671 17.9092 21.9993 18.0914 22.3035 18.3036C25.5839 18.3746 28 20.6305 28 24.5219V25.8471C28 29.5843 25.5 31.9766 25.5 33.7615V33.9282C25.8096 34.2858 26.0001 34.7705 26.0001 35.3045V39.2866C26.0001 40.3863 25.1941 41.2777 24.2 41.2777H9.80004C8.80593 41.2777 8 40.3863 8 39.2866V35.3045C8 34.7705 8.19046 34.2858 8.50009 33.9282V33.7615C8.50009 33.3282 8.11294 32.8369 7.02063 31.8837L7.00239 31.8675C6.3813 31.3164 5.69987 30.7403 4.92404 30.2591C3.03355 29.0953 0 28.368 0 25.5485C0 22.5122 1.62052 20.4752 3.97051 19.9606L4.00714 19.9521ZM9.6 24.4534V14.5977C9.6 13.4512 10.6312 12.4076 11.8 12.4076C12.9859 12.4076 14.0001 13.417 14.0001 14.5977V20.2614C14.9168 19.4626 16.6766 19.3645 17.8157 20.5694C18.1807 20.3617 18.5807 20.2682 18.9759 20.2655L19.0197 20.2652C19.9378 20.2667 20.8213 20.7446 21.1672 21.3735C23.6127 20.9676 25 22.0004 25 24.5219V25.8471C25 28.6776 22.7555 30.6512 22.5202 33.3136H11.4732C11.288 31.7431 10.1468 30.6403 8.99852 29.6379C8.20779 28.9364 7.41722 28.2861 6.50625 27.7215C5.04458 26.8206 2.99996 26.0957 2.99996 25.5485C2.99996 24.0942 3.55007 22.8108 5.20008 22.8108C7.40004 22.8108 8.51727 24.4534 9.6 24.4534Z"
        fill="white"
      />
    </svg>

    <svg
      class="swipe-tips__path"
      width="67"
      height="6"
      viewBox="0 0 67 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 5.5C26.0493 -0.729463 40.3906 -0.729473 66.5 5.5" />
    </svg>
  </div>
</template>
