var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import template from "./app-panels.html";
import css from "./app-panels.less";
import { VchatService } from "../../services/VchatService";
import anime from "animejs";
import { Direction, SwipeDetection } from "../../libs/SwipeDetection";
let AppPanelDesktop = class AppPanelDesktop {
    constructor(element, vchatService, defaultLogger) {
        this.element = element;
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.isOpen = true;
        this.toggleButtonText = "panel_desktop.hide_panel_button";
        this.swipePosition = 1;
        this.leftSwipeDiscovered = false;
        this.logger = this.defaultLogger.scopeTo(`AppPanels`);
        if (!this.vchatService.vchat.isViewer()) {
            throw new Error("this widget is reserved to viewers");
        }
        const swipe = new SwipeDetection(this.element);
        swipe.on("progress", ({ direction, distance }) => {
            this.lastDistance = distance;
            if (this.swipePosition === 1 && direction === Direction.Right) {
                this.lastPosition = `${distance * 50 - 50}%`;
            }
            else if (this.swipePosition === 0 && direction === Direction.Left) {
                this.lastPosition = `${distance * -50}%`;
            }
            else {
                this.lastPosition = `-${this.swipePosition * 50}%`;
            }
            this.element.style.transform = `translateX(${this.lastPosition})`;
        });
        swipe.on("end", ({ direction }) => {
            if (direction === void 0) {
                anime.remove(this.element);
                anime({
                    targets: this.element,
                    translateX: [this.lastPosition, `-${this.swipePosition * 50}%`],
                    duration: 250 * (1 - this.lastDistance),
                    easing: "easeOutQuad",
                });
            }
            else {
                if (this.swipePosition === 1 && direction === Direction.Right) {
                    this.leftSwipeDiscovered = true;
                    this.swipePosition = 0;
                }
                else if (this.swipePosition === 0 && direction === Direction.Left) {
                    this.swipePosition = 1;
                }
                anime.remove(this.element);
                anime({
                    targets: this.element,
                    translateX: [this.lastPosition, `-${this.swipePosition * 50}%`],
                    duration: 500 * (1 - this.lastDistance),
                    easing: "easeOutQuad",
                });
            }
        });
    }
    get goal() {
        return this.vchatService.vchat.goal;
    }
    get host() {
        return this.vchatService.vchat.host;
    }
    toggle() {
        this.isOpen = !this.isOpen;
        this.toggleButtonText = this.isOpen ? "panel_desktop.hide_panel_button" : "panel_desktop.show_panel_button";
    }
    get css() {
        return this.isOpen ? "" : "panel--closed";
    }
};
AppPanelDesktop = __decorate([
    customElement({ name: "app-panels", template, dependencies: [css] }),
    __param(2, ILogger),
    __metadata("design:paramtypes", [HTMLElement,
        VchatService, Object])
], AppPanelDesktop);
export default AppPanelDesktop;
