<template class="app-preset-message">
  <header class="dashboard-panel__header">
    <span t="tip_menu.title"></span>
    <div if.bind="!edit" class="dashboard-panel__actions">
      <button click.delegate="startEdit()" class="dashboard-panel__button">
        <i class="icon-edit"></i>
      </button>
    </div>
    <div else>
      <button class="dashboard-panel__button" click.delegate="addItem()">Ajouter un tarif</button>
    </div>
  </header>

  <template if.bind="edit">
    <textarea class="app-preset-message__input" value.bind="input"> </textarea>
    <div class="app-preset-message__buttons">
      <button click.delegate="cancelEdit()" t="tip_menu.cancel" class="cta cta--transparent">Annuler</button>
      <button click.delegate="stopEdit()" t="tip_menu.save" class="cta cta--infos">Sauvegarder</button>
    </div>
  </template>

  <template else>
    <template if.bind="!raw">
      <button click.delegate="startEdit()" class="dashboard-panel__label dashboard-panel__label--empty">
        <span t="tip_menu.not_defined">Aucun objectif actif</span>
        <span class="dashboard-panel__label" t="tip_menu.link">Ajouter un message auto</span>
      </button>
    </template>
    <template else>
      <preset-message class="app-preset-message__text" content.bind="raw"></preset-message>
      <button click.delegate="send()" class="cta cta--infos">Partager dans le tchat</button>
    </template>
  </template>
</template>
