var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import { DialogController } from "../../../dialog";
import { VchatService } from "../../../services/VchatService";
import template from "./app-custom-tip.html";
import css from "./app-custom-tip.less";
let AppCustomTip = class AppCustomTip {
    constructor(vchatService, dialogController, defaultLogger) {
        this.vchatService = vchatService;
        this.dialogController = dialogController;
        this.defaultLogger = defaultLogger;
        this.minimum = 5;
        this.logger = this.defaultLogger.scopeTo(`AppCustomTip`);
    }
    canActivate() {
        return this.vchatService.vchat.isViewer();
    }
    sendTip(event) {
        if (event && event.key !== "Enter") {
            return;
        }
        const amount = parseInt(this.amount);
        if (!isNaN(amount) && amount >= this.minimum) {
            this.dialogController.ok(parseInt(this.amount));
        }
    }
};
AppCustomTip = __decorate([
    customElement({ name: "app-custom-tip", template: template, dependencies: [css] }),
    __param(2, ILogger),
    __metadata("design:paramtypes", [VchatService,
        DialogController, Object])
], AppCustomTip);
export default AppCustomTip;
