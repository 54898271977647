<template class="dialog app-settings">
  <div class="dialog__header">
    <div class="dialog__title" t="settings.title">Settings</div>
  </div>

  <div class="dialog__content app-settings__content form">
    <label class="form__group"
      ><span class="form__label">${"settings.video" | t}</span>
      <select class="form__input form__input--select" value.bind="videoInputDevice">
        <option repeat.for="device of mediaDeviceService.videoInputDevices" model.bind="device">${device.label}</option>
      </select>
    </label>
    <label class="form__group">
      <span class="form__label">${"settings.audio" | t}</span>
      <select class="form__input form__input--select" value.bind="audioInputDevice">
        <option repeat.for="device of mediaDeviceService.audioInputDevices" model.bind="device">${device.label}</option>
      </select>
    </label>
    <button class="app-settings__button cta cta--warning" click.delegate="close()">Enregistrer les changements</button>
  </div>
</template>
