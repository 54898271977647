<template class="app-selector ${hasGroups|toClass:'groups'}">
  <selector-content class="app-selector__content">
    <au-slot></au-slot>
  </selector-content>
  <selector-nabar class="app-selector__navbar">
    <ul class="app-selector__navbar__list" if.bind="hasGroups">
      <li
        repeat.for="el of groupsIndex"
        class="app-selector__navbar__item ${_selectedGroup === $index|toClass:'app-selector__navbar__item--active'}"
        click.delegate="selectGroup($index, $event)"
        innerHTML.bind="el.innerHTML"
      ></li>
    </ul>
  </selector-nabar>
</template>
