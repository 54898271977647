<template class.bind="css" click.delegate="click($event)">
  <div>
    <template if.bind="isTip">
      <user-display id.bind="user"></user-display>a offert ${content.amount}
      <i class="icon-token"></i>
    </template>
    <template if.bind="isText">
      <user-display id.bind="user"></user-display>:
      <text-message class="text-message" class.bind="class" innerhtml.bind="content"></text-message
    ></template>
    <template if.bind="isPreset">
      <preset-message class="preset-message" class.bind="class" content.bind="content"></preset-message
    ></template>
  </div>
</template>
