/**
 * easing equations from http://gizma.com/easing/
 * t = current time
 * b = start value
 * c = delta value
 * d = duration
 */
export const inCubic = (t, b, c, d) => {
    t /= d;
    return c * t * t * t + b;
};
export const outCubic = (t, b, c, d) => {
    t /= d;
    t--;
    return c * (t * t * t + 1) + b;
};
export const inOutCubic = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1)
        return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
};
export const inBack = (t, b, c, d, s) => {
    s = s || 1.70158;
    return c * (t /= d) * t * ((s + 1) * t - s) + b;
};
export default {
    inCubic,
    outCubic,
    inOutCubic,
    inBack,
};
