var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import template from "./app-close-vchat.html";
import css from "./app-close-vchat.less";
import { VchatService } from "../../services/VchatService";
let AppCloseVchat = class AppCloseVchat {
    constructor(vchatService, defaultLogger) {
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppCloseVchat`);
        if (!this.vchatService.vchat.isHost()) {
            throw new Error("this widget is reserved to the host");
        }
        this.vchat = this.vchatService.vchat;
    }
    async close() {
        await this.vchat.close();
    }
};
AppCloseVchat = __decorate([
    customElement({ name: "app-close-vchat", template: template, dependencies: [css] }),
    __param(1, ILogger),
    __metadata("design:paramtypes", [VchatService, Object])
], AppCloseVchat);
export default AppCloseVchat;
