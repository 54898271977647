var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement } from "aurelia";
import template from "./animation-confetti.html";
import css from "./animation-confetti.less";
import { Point } from "./Point";
import { Particle } from "./Particle";
let AnimationConfetti = class AnimationConfetti {
    constructor(el) {
        this.el = el;
        this.viewWidth = 1000;
        this.viewHeight = 1000;
        this.particles = [];
        el.startAnimate = this.startAnimate.bind(this);
    }
    bound() {
        this.viewWidth = this.el.offsetWidth;
        this.viewHeight = this.el.offsetHeight;
    }
    startAnimate() {
        this.viewWidth = this.el.offsetWidth;
        this.viewHeight = this.el.offsetHeight;
        this.canvas.width = this.viewWidth;
        this.canvas.height = this.viewHeight;
        this.context = this.canvas.getContext("2d");
        requestAnimationFrame(() => this.draw());
    }
    draw() {
        this.context.clearRect(0, 0, this.viewWidth, this.viewHeight);
        if (this.particles.length < 512) {
            this.addParticles();
        }
        this.particles.forEach((p) => {
            p.update();
        });
        this.particles.forEach((p) => {
            p.draw(this.context);
        });
        if (!this.particles.every((p) => p.complete)) {
            requestAnimationFrame(() => this.draw());
        }
        else {
            this.particles = [];
        }
    }
    addParticles() {
        for (let i = 0; i < 4; i++) {
            const p0 = new Point(0, 0);
            const p1 = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
            const p2 = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
            const p3 = new Point(Math.random() * this.viewWidth, this.viewHeight + 64);
            this.particles.push(new Particle(p0, p1, p2, p3));
        }
        for (let i = 0; i < 4; i++) {
            const p0 = new Point(this.viewWidth, 0);
            const p1 = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
            const p2 = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
            const p3 = new Point(Math.random() * this.viewWidth, this.viewHeight + 64);
            this.particles.push(new Particle(p0, p1, p2, p3));
        }
    }
};
AnimationConfetti = __decorate([
    customElement({
        name: "animation-confetti",
        template: template,
        dependencies: [css],
    }),
    __metadata("design:paramtypes", [HTMLElement])
], AnimationConfetti);
export { AnimationConfetti };
