/* eslint-disable @typescript-eslint/ban-types */
import { DI } from "@aurelia/kernel";
/**
 * The dialog service for composing view & view model into a dialog
 */
export const IDialogService = DI.createInterface("IDialogService");
/**
 * The controller asscociated with every dialog view model
 */
export const IDialogController = DI.createInterface("IDialogController");
/**
 * An interface describing the object responsible for creating the dom structure of a dialog
 */
export const IDialogDomRenderer = DI.createInterface("IDialogDomRenderer");
/**
 * An interface describing the DOM structure of a dialog
 */
export const IDialogDom = DI.createInterface("IDialogDom");
/**
 * An interface describing the ojbect responsible for animating the dialog in various lifecycles,
 * based on dynamic user configuration
 */
export const IDialogAnimator = DI.createInterface("IDialogAnimator");
export const IDialogGlobalSettings = DI.createInterface("IDialogGlobalSettings");
// #endregion
