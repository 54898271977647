export class Unicode {
    static len(str) {
        return Array.from(str).length;
    }
    static substr(string, start, end) {
        const realStart = Unicode.toNumber(start, 0);
        const realEnd = Unicode.toNumber(end, string.length);
        if (realEnd === realStart) {
            return "";
        }
        else if (realEnd > realStart) {
            return Unicode.slice(string, realStart, realEnd);
        }
        else {
            return Unicode.slice(string, realEnd, realStart);
        }
    }
    static charAt(string, index) {
        const first = string.charCodeAt(index);
        let second;
        if (first >= 0xd800 && first <= 0xdbff && string.length > index + 1) {
            second = string.charCodeAt(index + 1);
            if (second >= 0xdc00 && second <= 0xdfff) {
                return string.substring(index, index + 2);
            }
        }
        return string[index];
    }
    static slice(string, start, end) {
        let accumulator = "";
        let character;
        let stringIndex = 0;
        let unicodeIndex = 0;
        const length = string.length;
        while (stringIndex < length) {
            character = Unicode.charAt(string, stringIndex);
            if (unicodeIndex >= start && unicodeIndex < end) {
                accumulator += character;
            }
            stringIndex += character.length;
            unicodeIndex += 1;
        }
        return accumulator;
    }
    static toNumber(value, fallback) {
        if (value === void 0) {
            return fallback;
        }
        else {
            return Number(value);
        }
    }
}
