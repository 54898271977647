var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, bindable, watch } from "aurelia";
import template from "./progress-bar.html";
import css from "./progress-bar.less";
let ProgressBar = class ProgressBar {
    constructor() {
        this.content = "";
        this.progress = 0.5;
        this.background = "";
    }
    progressChange() {
        var _a;
        if ((_a = this.progressEl) === null || _a === void 0 ? void 0 : _a.style) {
            if (this.background) {
                this.progressEl.style.background = this.background;
            }
            this.progressEl.style.width = Math.max(0, Math.min(100, this.progress * 100)) + "%";
        }
    }
    bound() {
        this.progressChange();
    }
};
__decorate([
    bindable,
    __metadata("design:type", Object)
], ProgressBar.prototype, "content", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], ProgressBar.prototype, "progress", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], ProgressBar.prototype, "background", void 0);
__decorate([
    watch("progress"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ProgressBar.prototype, "progressChange", null);
ProgressBar = __decorate([
    customElement({
        name: "progress-bar",
        template: template,
        dependencies: [css],
    })
], ProgressBar);
export default ProgressBar;
