<template class="dialog app-edit-goal">
  <div class="dialog__header">
    <div class="dialog__title" t="edit_goal.title"></div>
  </div>

  <div class="dialog__content app-edit-goal__content form">
    <label class="form__group">
      <span class="form__label">${"edit_goal.goal_title"|t}</span>
      <input class="form__input form__input--text" type="text" value.bind="title" />
    </label>
    <label class="form__group">
      <span class="form__label">${"edit_goal.goal_target"|t}</span>
      <input
        class="form__input form__input--text"
        keydown.delegate="ok($event)"
        type="number"
        pattern="\d*"
        value.bind="target"
      />
      <span class="form__subtitle">${"edit_goal.goal_equivalent"|t} ${euroValue}€</span>
    </label>
    <button class="app-edit-goal__button cta cta--warning" click.delegate="ok()">${"edit_goal.add_button"|t}</button>
  </div>
</template>
