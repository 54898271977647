<template class="app-top-tippers dashboard-panel">
  <header class="dashboard-panel__header" t="top_tippers.title"></header>
  <div class="dashboard-panel__table-wrapper">
    <table class="dashboard-table">
      <thead class="dashboard-table__head">
        <tr>
          <th t="top_tippers.rank_column_label"></th>
          <th t="top_tippers.username_column_label"></th>
          <th t="top_tippers.tips_column_label"></th>
        </tr>
      </thead>
      <tr repeat.for="element of list" ${$index+1}>
        <td class="dashboard-table__cell dashboard-table__cell--podium dashboard-table__cell--podium-${$index}">
          ${$index+1}
        </td>
        <td class="dashboard-table__cell dashboard-table__cell--accent">
          <user-display id.bind="element.user"></user-display>
        </td>
        <td class="dashboard-table__cell"><i class="icon-token"></i> ${element.tips}</td>
      </tr>
    </table>
  </div>
</template>
