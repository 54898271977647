var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger, bindable, watch, BindingMode } from "aurelia";
import { Configuration } from "../../../services/Configuration";
import { list, transformToEmoji, isEmoji } from "../../../libs/emojis";
import { Unicode } from "../../../libs/unicode";
import template from "./message-editor.html";
import css from "./message-editor.less";
const NON_BREAKING_SPACE = "\xa0";
let MessageEditor = class MessageEditor {
    constructor(element, configuration, defaultLogger) {
        this.element = element;
        this.configuration = configuration;
        this.defaultLogger = defaultLogger;
        this.disabled = false;
        this.blurOnSend = false;
        this.overflow = false;
        this.size = 0;
        this._selectorEmojiActive = false;
        this.click = () => {
            this.selectorEmojiActive = false;
        };
        this.logger = this.defaultLogger.scopeTo(`MessageInput`);
        this.element.focus = () => this.focus();
        this.maxSize = this.configuration.editor.maxSize;
    }
    get selectorEmojiList() {
        return list;
    }
    focus() {
        document.input = this.input;
        this.input.focus();
        setTimeout(() => {
            this.setSelection(this.input.lastChild || this.input);
        }, 100);
    }
    get selectorEmojiActive() {
        return this._selectorEmojiActive;
    }
    set selectorEmojiActive(value) {
        if (this._selectorEmojiActive === value) {
            return;
        }
        this._selectorEmojiActive = value;
        if (this._selectorEmojiActive) {
            setTimeout(() => {
                document.addEventListener("click", this.click);
            });
        }
        else {
            document.removeEventListener("click", this.click);
        }
    }
    get valid() {
        return !this.overflow && !this.disabled;
    }
    computeOverflow() {
        this.overflow = this.size > this.maxSize;
    }
    computeSize() {
        setTimeout(() => {
            const size = (this.input && Unicode.len(this.input.textContent.trim())) || 0;
            if (size !== this.size) {
                this.size = size;
            }
        }, 0);
        this.size = (this.input && Unicode.len(this.input.textContent.trim())) || 0;
    }
    insertNodes(anchor, elements) {
        const nodes = elements
            .map((el) => {
            if (typeof el === "string") {
                if (el.length === 0) {
                    return null;
                }
                return this.createTextNode(el);
            }
            return el;
        })
            .filter((el) => el !== null);
        nodes.forEach((el, i) => {
            if (i === nodes.length - 1) {
                this.input.replaceChild(el, anchor);
            }
            else {
                this.input.insertBefore(el, anchor);
            }
        });
    }
    blur(event) {
        if (this.selectorEmojiActive ||
            (event.relatedTarget instanceof Element && this.element.contains(event.relatedTarget))) {
            this.input.focus();
            event.preventDefault();
            return false;
        }
        this.element.dispatchEvent(new CustomEvent("blur", { bubbles: true }));
    }
    setSelection(node, offset) {
        const target = node.nodeName === "#text" ? node : node.firstChild || node;
        offset = offset !== void 0 ? offset : target.textContent.length || 0;
        window.getSelection().collapse(target, offset);
    }
    getSelection() {
        const selection = window.getSelection();
        let el = selection.anchorNode;
        while (el && el.parentNode && el !== this.input) {
            el = el.parentNode;
        }
        if (el) {
            return {
                anchorNode: selection.anchorNode,
                anchorOffset: selection.anchorOffset,
                focusNode: selection.focusNode,
                focusOffset: selection.focusOffset,
                isCollapsed: selection.isCollapsed,
                rangeCount: selection.rangeCount,
                type: selection.type,
                range: selection.getRangeAt(0),
            };
        }
    }
    inputEvent(event) {
        const isEnterKey = event.inputType === "insertCompositionText" &&
            event.data &&
            (event.data.charCodeAt(event.data.length - 1) === 10 || event.data.charCodeAt(event.data.length - 1) === 13);
        if (isEnterKey) {
            event.preventDefault();
            this.send();
        }
        return !isEnterKey;
    }
    keyDown(event) {
        const isEnterKey = event.code === "Enter" || event.code === "NumpadEnter" || event.key === "Enter";
        if (isEnterKey) {
            event.preventDefault();
            this.send();
        }
        return !isEnterKey;
    }
    emoji(event) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        if (this.selectorEmojiActive) {
            this.selectorEmojiActive = false;
            return;
        }
        this.selectorEmojiActive = true;
        const selection = this.getSelection() || this.selection;
        if (selection) {
            const anchor = selection.anchorNode;
            const offset = selection.anchorOffset;
            if (anchor === this.input) {
                this.selectorEmojiTarget = this.createTextNode("");
                this.input.appendChild(this.selectorEmojiTarget);
                this.setSelection(this.selectorEmojiTarget, 1);
            }
            else if (anchor.parentElement === this.input) {
                if (anchor.nodeName === "#text") {
                    const text = anchor.textContent;
                    this.selectorEmojiTarget = this.createTextNode("");
                    const insert = [];
                    insert.push(this.selectorEmojiTarget);
                    this.insertNodes(anchor, [Unicode.substr(text, 0, offset), ...insert, Unicode.substr(text, offset)]);
                    if (this.selectorEmojiTarget) {
                        this.setSelection(this.selectorEmojiTarget, this.selectorEmojiTarget.textContent.length);
                    }
                }
                else {
                    this.selectorEmojiTarget = anchor;
                }
            }
            else {
                this.selectorEmojiTarget = this.createTextNode("");
                this.input.appendChild(this.selectorEmojiTarget);
                this.setSelection(this.selectorEmojiTarget, 1);
            }
        }
        else {
            this.selectorEmojiTarget = this.createTextNode("");
            this.input.appendChild(this.selectorEmojiTarget);
            this.setSelection(this.selectorEmojiTarget, 1);
        }
        return false;
    }
    selectedEmoji(event) {
        try {
            this.selectorEmojiTarget.textContent = event.detail.item.value[0];
            this.selectorEmojiActive = false;
            this.setSelection(this.selectorEmojiTarget, 1);
            this.selectorEmojiTarget = null;
            if (this.input) {
                this.input.focus();
            }
        }
        catch (e) {
            this.logger.error("error selectedEmoji", e);
        }
    }
    createTextNode(text) {
        return document.createTextNode(text ? text : NON_BREAKING_SPACE);
    }
    validateElement() {
        const elements = Array.from(this.input.childNodes);
        for (const element of elements) {
            if (element.nodeName === "#text") {
                continue;
            }
            if (element.nodeName === "BR") {
                continue;
            }
            const textNode = this.createTextNode(element.textContent);
            this.input.replaceChild(textNode, element);
        }
    }
    sanitizedValue() {
        this.validateElement();
        const elements = Array.from(this.input.childNodes);
        for (const element of elements) {
            if (element.nodeName === "#text") {
                continue;
            }
            const textNode = this.createTextNode(element.textContent);
            this.input.replaceChild(textNode, element);
        }
        return this.input.innerHTML.trim();
    }
    send(evt) {
        var _a;
        if (this.debounceSend && Date.now() - this.debounceSend < 100) {
            return;
        }
        this.debounceSend = Date.now();
        if (!this.valid) {
            return;
        }
        if (evt) {
            evt.stopImmediatePropagation();
            evt.stopPropagation();
            this.input.focus();
        }
        if (this.disabled) {
            return;
        }
        const value = Array.from(transformToEmoji(this.sanitizedValue()
            .replace(/&nbsp;/g, " ")
            .trim()))
            .map((s) => (isEmoji(s) ? `<emoji>${s}</emoji>` : s))
            .join("");
        this.input.textContent = "";
        if (this.blurOnSend) {
            (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.blur();
        }
        const sendEvent = new CustomEvent("send", {
            detail: { value },
            bubbles: true,
        });
        this.element.dispatchEvent(sendEvent);
        return false;
    }
};
__decorate([
    bindable({ mode: BindingMode.twoWay }),
    __metadata("design:type", String)
], MessageEditor.prototype, "value", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], MessageEditor.prototype, "disabled", void 0);
__decorate([
    bindable,
    __metadata("design:type", Number)
], MessageEditor.prototype, "maxSize", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], MessageEditor.prototype, "blurOnSend", void 0);
__decorate([
    watch("maxSize"),
    watch("size"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MessageEditor.prototype, "computeOverflow", null);
__decorate([
    watch("value"),
    watch("_size"),
    watch("input.textContent"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MessageEditor.prototype, "computeSize", null);
MessageEditor = __decorate([
    customElement({
        name: "message-editor",
        template: template,
        dependencies: [css],
    }),
    __param(2, ILogger),
    __metadata("design:paramtypes", [HTMLElement,
        Configuration, Object])
], MessageEditor);
export default MessageEditor;
