var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { inject } from "aurelia";
let Sound = class Sound {
    constructor() {
        this.sounds = new Map();
        this.isPlaying = false;
    }
    async play(id) {
        if (this.isPlaying) {
            return;
        }
        this.isPlaying = true;
        try {
            if (!this.sounds.has(id)) {
                this.sounds.set(id, await this.load(id));
            }
            const audio = this.sounds.get(id);
            await this.playAll(audio);
        }
        finally {
            this.isPlaying = false;
        }
    }
    async playAll(audio) {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });
        const errorListener = (err) => {
            reject(err);
            audio.removeEventListener("error", errorListener);
            audio.removeEventListener("ended", endedListener);
        };
        const endedListener = () => {
            resolve();
            audio.removeEventListener("error", errorListener);
            audio.removeEventListener("ended", endedListener);
        };
        audio.addEventListener("error", errorListener);
        audio.addEventListener("ended", endedListener);
        await audio.play();
        await promise;
    }
    async load(id) {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });
        const audio = new Audio(`/static/sounds/${id}.mp3`);
        const errorListener = (err) => {
            reject(err);
            this.sounds.delete(id);
            audio.removeEventListener("error", errorListener);
            audio.removeEventListener("canplaythrough", canplaythroughListener);
        };
        const canplaythroughListener = () => {
            resolve();
            audio.removeEventListener("error", errorListener);
            audio.removeEventListener("canplaythrough", canplaythroughListener);
        };
        audio.addEventListener("error", errorListener);
        audio.addEventListener("canplaythrough", canplaythroughListener);
        await promise;
        return audio;
    }
};
Sound = __decorate([
    inject()
], Sound);
export { Sound };
