import { IDialogAnimator, IDialogDomRenderer, IDialogGlobalSettings, } from "./dialog-interfaces";
import { Registration } from "@aurelia/kernel";
import { IPlatform } from "aurelia";
export class DefaultDialogGlobalSettings {
    constructor() {
        this.lock = true;
        this.startingZIndex = 1000;
        this.rejectOnCancel = false;
    }
    static register(container) {
        Registration.singleton(IDialogGlobalSettings, this).register(container);
    }
    restoreFocus(el) {
        el.focus();
    }
}
const baseWrapperCss = "position:absolute;width:100%;height:100%;top:0;left:0;";
export class DefaultDialogDomRenderer {
    constructor(p) {
        this.p = p;
        this.wrapperCss = `${baseWrapperCss} display:flex;`;
        this.overlayCss = `${baseWrapperCss} background: rgba(0, 0, 0, 0.8); display: flex; align-items: center; justify-content: center; z-index:2;`;
        this.hostCss = "position:relative;";
    }
    static register(container) {
        Registration.singleton(IDialogDomRenderer, this).register(container);
    }
    render(dialogHost, settings) {
        const doc = this.p.document;
        const h = (name, css) => {
            const el = doc.createElement(name);
            if (css != null) {
                el.style.cssText = css;
            }
            return el;
        };
        const wrapper = dialogHost.appendChild(h("au-dialog-container", this.wrapperCss));
        const overlay = wrapper.appendChild(h("au-dialog-overlay", this.overlayCss));
        const host = overlay.appendChild(h("div", this.hostCss));
        return new DefaultDialogDom(wrapper, overlay, host, settings);
    }
}
DefaultDialogDomRenderer.inject = [IPlatform];
export class DefaultDialogDom {
    constructor(wrapper, overlay, contentHost, s) {
        var _a;
        this.wrapper = wrapper;
        this.overlay = overlay;
        this.contentHost = contentHost;
        this.subs = new Set();
        overlay.addEventListener((this.e = (_a = s.mouseEvent) !== null && _a !== void 0 ? _a : "click"), this);
    }
    /**
     * @internal
     */
    handleEvent(e) {
        this.subs.forEach((sub) => sub.handleOverlayClick(e));
    }
    subscribe(subscriber) {
        this.subs.add(subscriber);
    }
    unsubscribe(subscriber) {
        this.subs.delete(subscriber);
    }
    dispose() {
        this.wrapper.remove();
        this.overlay.removeEventListener(this.e, this);
        this.subs.clear();
    }
}
/**
 * A default implementation for IDialogRenderer interface
 */
export class DefaultDialogAnimator {
    static register(container) {
        Registration.singleton(IDialogAnimator, this).register(container);
    }
    attaching(dialogDom, animation = {}) {
        return this.animate(dialogDom.contentHost, animation.attaching, animation.ignoreTransitions);
    }
    detaching(dialogDom, animation = {}) {
        return this.animate(dialogDom.contentHost, animation.detaching, animation.ignoreTransitions);
    }
    animate(host, params, doNotWait) {
        if (params != null) {
            const animation = host.animate(...params);
            if (!doNotWait) {
                return animation.finished;
            }
        }
    }
}
