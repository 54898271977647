var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import template from "./app-stream-quality.html";
import css from "./app-stream-quality.less";
import { LinksService } from "@tawenda-npm/aurelia-plugin-live";
import { VchatService } from "../../services/VchatService";
import { StreamingQualityService } from "../../services/StreamingQualityService";
let AppStreamQuality = class AppStreamQuality {
    constructor(element, streamingQualityService, linksService, vchatService, defaultLogger) {
        this.element = element;
        this.streamingQualityService = streamingQualityService;
        this.linksService = linksService;
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppStream`);
        if (!this.vchatService.vchat.isHost()) {
            throw new Error("this widget is reserved to the host");
        }
        this.vchat = this.vchatService.vchat;
    }
    get isActive() {
        return !!this.linksService.watchers.length;
    }
    get quality() {
        return this.streamingQualityService.quality;
    }
    get qualityClass() {
        if (this.streamingQualityService.quality < 0.1) {
            return "stream_quality-disconnected";
        }
        else if (this.streamingQualityService.quality <= 0.5) {
            return "stream_quality-poor";
        }
        else if (this.streamingQualityService.quality <= 0.7) {
            return "stream_quality-medium";
        }
        else if (this.streamingQualityService.quality <= 0.9) {
            return "stream_quality-good";
        }
        else {
            return "stream_quality-excellent";
        }
    }
    get qualityLabel() {
        if (this.streamingQualityService.quality < 0.1) {
            return "stream_quality.disconnected";
        }
        else if (this.streamingQualityService.quality <= 0.5) {
            return "stream_quality.poor";
        }
        else if (this.streamingQualityService.quality <= 0.7) {
            return "stream_quality.medium";
        }
        else if (this.streamingQualityService.quality <= 0.9) {
            return "stream_quality.good";
        }
        else {
            return "stream_quality.excellent";
        }
    }
};
AppStreamQuality = __decorate([
    customElement({ name: "app-stream-quality", template, dependencies: [css] }),
    __param(4, ILogger),
    __metadata("design:paramtypes", [HTMLElement,
        StreamingQualityService,
        LinksService,
        VchatService, Object])
], AppStreamQuality);
export default AppStreamQuality;
