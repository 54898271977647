var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import template from "./app-goal.html";
import css from "./app-goal.less";
import { VchatService } from "../../services/VchatService";
import AppEditGoal from "../dialogs/app-edit-goal";
import { DialogService, IDialogService } from "../../dialog";
import { GoalService } from "../../services/GoalService";
let AppGoal = class AppGoal {
    constructor(vchatService, goal, dialogService, defaultLogger) {
        this.vchatService = vchatService;
        this.goal = goal;
        this.dialogService = dialogService;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`Goal`);
        this.vchat = this.vchatService.vchat;
    }
    get isViewer() {
        return this.vchat.isViewer();
    }
    get isHost() {
        return this.vchat.isHost();
    }
    get css() {
        let css = this.vchat.isViewer() ? "app-goal--viewer" : "app-goal--host dashboard-panel";
        if (this.goal.progress >= 1) {
            css += " app-goal--completed";
        }
        return css;
    }
    async addGoal() {
        if (!this.vchat.isHost()) {
            return;
        }
        const { controller } = await this.dialogService.open({
            component: () => AppEditGoal,
            keyboard: "Escape",
            overlayDismiss: true,
        });
        const response = await controller.closed;
        if (response.status === "ok" /* Ok */) {
            const value = response.value;
            await this.vchat.addGoal(value.title, value.target);
        }
    }
    async deactivateGoal() {
        if (!this.vchat.isHost()) {
            return;
        }
        await this.vchat.deactivateGoal();
    }
};
AppGoal = __decorate([
    customElement({ name: "app-goal", template, dependencies: [css] }),
    __param(2, IDialogService),
    __param(3, ILogger),
    __metadata("design:paramtypes", [VchatService,
        GoalService,
        DialogService, Object])
], AppGoal);
export default AppGoal;
