var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { bindable, customElement, ILogger, BindingMode } from "aurelia";
import template from "./toggle-button.html";
import css from "./toggle-button.less";
let ToggleButton = class ToggleButton {
    constructor(element, defaultLogger) {
        this.element = element;
        this.defaultLogger = defaultLogger;
        this.active = false;
        this.activeContent = "";
        this.inactiveContent = "";
        this.cssClasses = [];
        this.logger = this.defaultLogger.scopeTo(`ToggleButton`);
    }
    async binding() {
        this.cssClasses = [...this.element.classList].filter((cls) => cls != "au" && !cls.match(/--/));
    }
    toggle() {
        this.active = !this.active;
    }
    get content() {
        return this.active ? this.activeContent : this.inactiveContent;
    }
    get css() {
        const suffix = this.active ? "--active" : "--inactive";
        return [`toggle-button${suffix}`, ...this.cssClasses.map((cls) => cls + suffix)].join(" ");
    }
};
__decorate([
    bindable({ mode: BindingMode.twoWay }),
    __metadata("design:type", Object)
], ToggleButton.prototype, "active", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], ToggleButton.prototype, "activeContent", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], ToggleButton.prototype, "inactiveContent", void 0);
ToggleButton = __decorate([
    customElement({
        name: "toggle-button",
        template: template,
        dependencies: [css],
    }),
    __param(1, ILogger),
    __metadata("design:paramtypes", [HTMLElement, Object])
], ToggleButton);
export default ToggleButton;
