var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import template from "./app-tips.html";
import css from "./app-tips.less";
import { VchatService } from "../../services/VchatService";
import { DialogService, IDialogService } from "../../dialog";
import AppCustomTip from "../dialogs/app-custom-tip";
import { InsufficientFundsError } from "../../services/VchatApi";
import AppWalletError from "../dialogs/app-wallet-error";
import AppAlertError from "../dialogs/app-alert-error";
let AppTips = class AppTips {
    constructor(element, vchatService, dialogService, defaultLogger) {
        this.element = element;
        this.vchatService = vchatService;
        this.dialogService = dialogService;
        this.defaultLogger = defaultLogger;
        this.tips = [5, 10, 25, 100, 250, 500];
        this.isTipping = false;
        this.isOpen = false;
        this.logger = this.defaultLogger.scopeTo(`AppTips`);
        if (!this.vchatService.vchat.isViewer()) {
            throw new Error("this widget is reserved to viewers");
        }
        this.vchat = this.vchatService.vchat;
    }
    toggle() {
        this.isOpen = !this.isOpen;
    }
    tip(event, amount) {
        if (this.isTipping) {
            return;
        }
        this.isTipping = true;
        if (event === null || event === void 0 ? void 0 : event.target) {
            event.target.classList.add("app-tips__item--animate");
        }
        this.vchat
            .sendTip(amount)
            .then()
            .catch((err) => {
            if (err instanceof InsufficientFundsError) {
                this.dialogService.open({
                    component: () => AppWalletError,
                    keyboard: "Escape",
                    overlayDismiss: true,
                });
            }
            else {
                this.logger.error(err);
                this.dialogService.open({
                    component: () => AppAlertError,
                    keyboard: "Escape",
                    overlayDismiss: true,
                });
            }
        })
            .finally(() => setTimeout(() => {
            this.isTipping = false;
            this.element
                .querySelectorAll(".app-tips__item")
                .forEach((el) => el.classList.remove("app-tips__item--animate"));
        }, 250));
    }
    async customTip() {
        if (this.isTipping) {
            return;
        }
        const { controller } = await this.dialogService.open({
            component: () => AppCustomTip,
            keyboard: "Escape",
            overlayDismiss: true,
        });
        const response = await controller.closed;
        if (response.status === "ok" /* Ok */) {
            this.tip(null, response.value);
        }
    }
};
AppTips = __decorate([
    customElement({ name: "app-tips", template, dependencies: [css] }),
    __param(2, IDialogService),
    __param(3, ILogger),
    __metadata("design:paramtypes", [HTMLElement,
        VchatService,
        DialogService, Object])
], AppTips);
export default AppTips;
