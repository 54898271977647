<template class="dialog app-custom-tip">
  <div class="dialog__header">
    <div class="dialog__title" t="custom_tip.title"></div>
    <div class="dialog__subtitle" t="custom_tip.subtitle"></div>
  </div>

  <div class="app-custom-tip__content">
    <div class="app-custom-tip__input">
      <input keydown.delegate="sendTip($event)" type="number" min.bind="minimum" pattern="\d*" value.bind="amount" />
    </div>

    <button class="app-custom-tip__button" click.delegate="sendTip()">
      <i class="icon-check"></i>
    </button>
  </div>
</template>
