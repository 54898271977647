var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ILogger, inject } from "aurelia";
import { VchatApi } from "./VchatApi";
let UserPreferences = class UserPreferences {
    constructor(defaultLogger, vchatApi) {
        this.vchatApi = vchatApi;
        this.data = {};
        this.userPreferencesUpdated = () => {
            this.data = this.userPreferencesSubscription.data;
        };
        this.logger = defaultLogger.scopeTo(`Preferences`);
        this.userPreferencesSubscription = this.vchatApi.getUserPreferencesSubscription();
        this.userPreferencesSubscription.addListener("change", this.userPreferencesUpdated);
    }
    async setPreference(key, data) {
        console.log(key, data);
        this.data[key] = data;
        return this.vchatApi.setUserPreferencesSubscription(key, data);
    }
};
UserPreferences = __decorate([
    inject(),
    __param(0, ILogger),
    __metadata("design:paramtypes", [Object, VchatApi])
], UserPreferences);
export { UserPreferences };
