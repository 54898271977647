var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ILogger, inject, IRouter } from "aurelia";
import { Authentication as AuthenticationBase } from "@tawenda-npm/aurelia-plugin-live";
import { Configuration } from "./Configuration";
import { Sentry } from "../sentry/Sentry";
let Authentication = class Authentication {
    constructor(router, auth, configuration, sentry, defaultLogger) {
        this.router = router;
        this.auth = auth;
        this.configuration = configuration;
        this.sentry = sentry;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`Authentication`);
        this.ready = Promise.all([
            auth.ready,
            fetch(`${document.location.origin}/api/v1/auth/nonce/`)
                .then((res) => res.json())
                .then((json) => {
                if (json === null || json === void 0 ? void 0 : json.token) {
                    return this.auth.authenticate(json.token);
                }
                else {
                    throw new Error("Missing auth");
                }
            })
                .catch((err) => {
                if (this.configuration.environment !== "production") {
                    return this.auth.testAuthenticate();
                }
                else {
                    throw err;
                }
            }),
        ]).then(() => {
            sentry.setUserContext({ id: this.user });
        });
    }
    get sessionTokens() {
        var _a;
        return (_a = this.auth.session) === null || _a === void 0 ? void 0 : _a.tokens;
    }
    get user() {
        return this.auth.user;
    }
    get sessionClient() {
        var _a;
        return (_a = this.auth.session) === null || _a === void 0 ? void 0 : _a.client;
    }
    get username() {
        return this.auth.username;
    }
    get session() {
        return this.auth.session;
    }
    resetUser() {
        this.auth.resetUser();
    }
};
Authentication = __decorate([
    inject(),
    __param(0, IRouter),
    __param(4, ILogger),
    __metadata("design:paramtypes", [Object, AuthenticationBase,
        Configuration,
        Sentry, Object])
], Authentication);
export { Authentication };
