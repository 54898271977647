var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger, watch } from "aurelia";
import template from "./app-widgets.html";
import css from "./app-widgets.less";
import { VchatService } from "../../services/VchatService";
import { VchatStatistics } from "../../services/VchatStatistics";
const addLeadingZero = (nb) => {
    return nb < 10 ? "0" + nb : nb.toString();
};
let AppWidgets = class AppWidgets {
    constructor(vchatService, statistics, defaultLogger) {
        this.vchatService = vchatService;
        this.statistics = statistics;
        this.settings = new Map([
            [
                "visibility",
                {
                    value: "-",
                    label: "widgets.visibility_label",
                    translateValue: true,
                },
            ],
            [
                "type",
                {
                    value: "-",
                    label: "widgets.type_label",
                    translateValue: true,
                },
            ],
            [
                "price",
                {
                    value: '- <i class="icon-token">',
                    label: "widgets.price_label",
                },
            ],
            [
                "profit",
                {
                    value: "- €",
                    label: "widgets.profit_label",
                },
            ],
            [
                "duration",
                {
                    value: "--:--:--",
                    label: "widgets.duration_label",
                },
            ],
            [
                "active-viewers",
                {
                    value: "-",
                    label: "widgets.active_viewers_label",
                },
            ],
            [
                "tips",
                {
                    value: "-",
                    label: "widgets.tips_label",
                },
            ],
            [
                "total-viewers",
                {
                    value: "-",
                    label: "widgets.total_viewers_label",
                },
            ],
        ]);
        this.logger = defaultLogger.scopeTo("appWidgets");
        if (!this.vchatService.vchat.isHost()) {
            throw new Error("this widget is reserved to the host");
        }
        this.vchat = this.vchatService.vchat;
        this.updatePrice();
        this.updateVisibility();
        this.updateType();
        this.updateDuration();
        this.updateProfit();
        this.updateTips();
        this.updateTotalViewers();
        this.updateActiveViewers();
    }
    get widgets() {
        return [...this.settings.values()];
    }
    updateVisibility() {
        if (!this.vchat.access) {
            this.settings.get("visibility").value = "-";
        }
        else {
            this.settings.get("visibility").value =
                "widgets.visibility." + (this.vchat.access === "all" ? "all" : "only_fans");
        }
    }
    updateType() {
        if (!this.vchat.pay) {
            this.settings.get("type").value = "-";
        }
        else {
            this.settings.get("type").value =
                "widgets.type." + (this.vchat.price === 0 ? "free" : this.vchat.pay === "all" ? "chargeable" : "free_for_fans");
        }
    }
    updatePrice() {
        if (isNaN(this.vchat.price)) {
            this.settings.get("price").value = `- <i class="icon-token">`;
        }
        else {
            this.settings.get("price").value = `${this.vchat.price} <i class="icon-token">`;
        }
    }
    updateDuration() {
        if (isNaN(this.statistics.duration)) {
            this.settings.get("duration").value = "--:--:--";
        }
        else {
            const duration = this.statistics.duration;
            const hours = Math.floor(duration / 3600);
            const minutes = Math.floor((duration % 3600) / 60);
            const seconds = Math.round(duration % 60);
            this.settings.get("duration").value = `${addLeadingZero(hours)}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`;
        }
    }
    updateProfit() {
        if (isNaN(this.statistics.minutes)) {
            this.settings.get("profit").value = "- €";
        }
        else {
            const profit = this.vchat.pointToEuro(this.statistics.minutes * this.vchat.price + this.statistics.tips);
            this.settings.get("profit").value = `${profit} €`;
        }
    }
    updateTips() {
        if (isNaN(this.statistics.tips)) {
            this.settings.get("tips").value = "-";
        }
        else {
            this.settings.get("tips").value = `${this.statistics.tips} <i class="icon-token"></i>`;
            this.updateProfit();
        }
    }
    updateActiveViewers() {
        if (isNaN(this.statistics.activeViewers)) {
            this.settings.get("active-viewers").value = "-";
        }
        else {
            this.settings.get("active-viewers").value = `${this.statistics.activeViewers}`;
        }
    }
    updateTotalViewers() {
        if (isNaN(this.statistics.totalViewers)) {
            this.settings.get("total-viewers").value = "-";
        }
        else {
            this.settings.get("total-viewers").value = `${this.statistics.totalViewers}`;
        }
    }
};
__decorate([
    watch("statistics.duration"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppWidgets.prototype, "updateDuration", null);
__decorate([
    watch("statistics.minutes"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppWidgets.prototype, "updateProfit", null);
__decorate([
    watch("statistics.tips"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppWidgets.prototype, "updateTips", null);
__decorate([
    watch("statistics.activeViewers"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppWidgets.prototype, "updateActiveViewers", null);
__decorate([
    watch("statistics.totalViewers"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppWidgets.prototype, "updateTotalViewers", null);
AppWidgets = __decorate([
    customElement({ name: "app-widgets", template, dependencies: [css] }),
    __param(2, ILogger),
    __metadata("design:paramtypes", [VchatService,
        VchatStatistics, Object])
], AppWidgets);
export default AppWidgets;
