import { IContainer, noop, Registration } from "@aurelia/kernel";
import { IDialogGlobalSettings } from "./dialog-interfaces";
import { DefaultDialogGlobalSettings, DefaultDialogAnimator, DefaultDialogDomRenderer } from "./dialog-default-impl";
import { DialogService } from "./dialog-service";
import { AppTask } from "aurelia";
function createConfiguration(settingsProvider, registrations = []) {
    return {
        settingsProvider: settingsProvider,
        register: (ctn) => ctn.register(...registrations, AppTask.with(IContainer)
            .beforeCreate()
            .call((c) => settingsProvider(c.get(IDialogGlobalSettings)))),
        customize(cb, regs) {
            return createConfiguration(cb, regs !== null && regs !== void 0 ? regs : registrations);
        },
    };
}
/**
 * A noop configuration for Dialog, should be used as:
```ts
DialogConfiguration.customize(settings => {
  // adjust default value of the settings
}, [all_implementations_here])
```
 */
export const DialogConfiguration = createConfiguration(() => {
    throw new Error("Invalid dialog configuration. " +
        "Specify the implementations for " +
        "<IDialogService>, <IDialogGlobalSettings>, <IDialogDomRenderer> and <IDialogAnimator>, " +
        "or use the DefaultDialogConfiguration export.");
}, [
    class NoopDialogGlobalSettings {
        static register(container) {
            container.register(Registration.singleton(IDialogGlobalSettings, this));
        }
    },
]);
export const DialogDefaultConfiguration = createConfiguration(noop, [
    DialogService,
    DefaultDialogGlobalSettings,
    DefaultDialogDomRenderer,
    DefaultDialogAnimator,
]);
