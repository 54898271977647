var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { valueConverter } from '@aurelia/runtime-html';
let TimeValueConverter = class TimeValueConverter {
    toView(value) {
        const date = new Date(value);
        return `${this.padStart(date.getHours())}:${this.padStart(date.getMinutes())}`;
    }
    padStart(val, size = 2) {
        let value = val ? val.toString() : "";
        while (value.length < size) {
            value = "0" + value;
        }
        return value;
    }
};
TimeValueConverter = __decorate([
    valueConverter('time')
], TimeValueConverter);
export { TimeValueConverter };
