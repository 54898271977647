var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement } from "aurelia";
import template from "./app-switch-camera.html";
import css from "./app-switch-camera.less";
import { AppState } from "../../services/AppState";
import { VchatService } from "../../services/VchatService";
let AppSwitchCamera = class AppSwitchCamera {
    constructor(appState, vchatService) {
        this.appState = appState;
        this.vchatService = vchatService;
    }
    switchCamera() {
        this.vchatService.mediaDeviceService.nextVideoInputDevice();
    }
};
AppSwitchCamera = __decorate([
    customElement({ name: "app-switch-camera", template, dependencies: [css] }),
    __metadata("design:paramtypes", [AppState, VchatService])
], AppSwitchCamera);
export default AppSwitchCamera;
