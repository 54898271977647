<template class="app-top-tip ${css}">
  <template if.bind="isVisible">
    <div class="app-top-tip__content">
      <div class="app-top-tip__label" t="top_tip.title"></div>
      <div class="app-top-tip__description">
        <span>${user.username}</span>
        <span>${amount}<i class="icon-token"></i></span>
      </div>
    </div>
  </template>
</template>
