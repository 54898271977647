import { Vchat } from "./Vchat";
export class VchatViewer extends Vchat {
    constructor(configuration, observerEngine, vchatApi, authentication, presenceNotifierFactory, logger, appState, linksService, sentry, vchat) {
        super(observerEngine, vchatApi, authentication, presenceNotifierFactory, linksService, logger, sentry, vchat);
        this.configuration = configuration;
        this.appState = appState;
        this._avatarUrl = null;
        this._lowHold = false;
        this.appState.type = "viewer";
    }
    get avatarUrl() {
        return this._avatarUrl;
    }
    get lowHold() {
        return this._lowHold;
    }
    async init() {
        var _a;
        const superInitPromise = super.init();
        await this.vchatApi.ready;
        this.vchatApi
            .getVchatHostAvatar(this.id)
            .then((url) => (this._avatarUrl = url))
            .catch(this.logger.error);
        await this.setActiveSession(true);
        this.vchatUserSubscription = this.vchatApi.getVchatUserSubscription(this.vchat.id);
        await this.vchatUserSubscription.ready;
        this.lastOpenSession = this.vchatUserSubscription.data.find((doc) => doc.status === "open");
        this._lowHold = (_a = this.lastOpenSession) === null || _a === void 0 ? void 0 : _a.low_hold;
        this.vchatUserSubscription.addListener("change", () => {
            var _a, _b, _c;
            if (!this.isStreamUpToDate()) {
                return;
            }
            this.recomputeActiveSession();
            const openSession = this.vchatUserSubscription.data.find((doc) => doc.status === "open");
            if (!openSession) {
                this.logger.warn((_a = this.lastOpenSession) === null || _a === void 0 ? void 0 : _a.close_reason);
                this.redirectAfterClose((_b = this.lastOpenSession) === null || _b === void 0 ? void 0 : _b.close_reason);
            }
            else {
                this.lastOpenSession = openSession;
                this._lowHold = (_c = this.lastOpenSession) === null || _c === void 0 ? void 0 : _c.low_hold;
            }
        });
        this.recomputeActiveSession();
        await superInitPromise;
    }
    isStreamUpToDate() {
        if (!this.lastUserChatDocument) {
            return true;
        }
        const doc = this.vchatUserSubscription.data.find((d) => d.id === this.lastUserChatDocument.id);
        if (!doc) {
            return false;
        }
        const isUpToDate = new Date(doc.updatedAt) >= new Date(this.lastUserChatDocument.updatedAt);
        if (isUpToDate) {
            this.lastUserChatDocument = null;
        }
        return isUpToDate;
    }
    recomputeActiveSession() {
        var _a, _b;
        this._isActiveSession =
            !this.isStreamUpToDate() ||
                ((_b = (_a = this.vchatUserSubscription) === null || _a === void 0 ? void 0 : _a.data.find((doc) => doc.status === "open")) === null || _b === void 0 ? void 0 : _b.session) === this.authentication.session.id;
        this.appState.activeSession = this._isActiveSession;
    }
    async setActiveSession(force) {
        this.lastUserChatDocument = await this.vchatApi.join(this.id, force);
    }
    async leave() {
        try {
            await this.vchatApi.leave(this.id);
        }
        catch (err) {
            //ignore
        }
        this.redirectAfterClose("user_close");
    }
    async sendTip(amount) {
        await this.vchatApi.postTip(this.id, { amount });
    }
    credit() {
        window.open(this.configuration.urls.creditWallet.replace("{vchat_id}", this.id), "_blank");
    }
}
