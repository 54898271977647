export class VchatClosedError extends Error {
}
export class VchatNotFoundError extends Error {
}
export class Vchat {
    constructor(observerEngine, vchatApi, authentication, presenceNotifierFactory, linksService, logger, sentry, vchat) {
        this.vchatApi = vchatApi;
        this.authentication = authentication;
        this.presenceNotifierFactory = presenceNotifierFactory;
        this.linksService = linksService;
        this.logger = logger;
        this.sentry = sentry;
        this.vchat = vchat;
        this._isActiveSession = false;
        this.vchatUpdated = () => {
            const vchat = this.vchatSubscription.data;
            if (!vchat) {
                this.status = "closed";
                this.redirectAfterClose("host_close");
                return;
            }
            this.linksService.sfu = vchat.sfu;
            this.hostSession = vchat.host_session;
            this.recomputeActiveSession();
            this.vchat.ban = this.vchatSubscription.data.ban;
            if (vchat.top_tip) {
                this.topTip = {
                    user: vchat.top_tip.user,
                    amount: vchat.top_tip.amount,
                };
            }
            if (vchat.goal !== this._goal) {
                this._goal = vchat.goal;
                this.goalChanged();
            }
        };
        this.id = vchat.id;
        this.status = vchat.status;
        this.host = vchat.host;
        this._isHost = this.host === this.authentication.user;
        this.access = vchat.access;
        this.pay = vchat.pay;
        this.closeRedirect = vchat.close_redirect;
        this.disconnectRedirect = vchat.disconnect_redirect;
        this.pointValue = vchat.point_value;
        this.share = vchat.share;
        this.channel = vchat.channel;
        this.price = vchat.price;
        this._goal = vchat.goal;
        this.sentry.vchat = this;
        this.ready = this.init();
        observerEngine.subscribe(this.authentication, "session", () => this.recomputeActiveSession());
        observerEngine.subscribe(this.authentication, "user", () => (this.user = this.authentication.user));
        this.user = this.authentication.user;
    }
    get goal() {
        return this._goalRest;
    }
    async init() {
        this.presenceNotifier = this.presenceNotifierFactory.build("vchat");
        await this.vchatApi.ready;
        this.vchatSubscription = this.vchatApi.getVchatSubscription(this.id);
        this.vchatSubscription.addListener("change", this.vchatUpdated);
        const promises = [this.vchatSubscription.ready];
        if (this._goal) {
            this.goalChanged();
            promises.push(this.goalSubscription.ready);
        }
        await Promise.all(promises);
    }
    get isAdmin() {
        var _a, _b;
        return ((_b = (_a = this.authentication.sessionTokens) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.audiences.has("tawenda:vchat:admin")) || false;
    }
    isActiveSession() {
        return this._isActiveSession;
    }
    goalChanged() {
        if (this.goalSubscription) {
            this._goalRest = void 0;
            this.goalSubscription.destroy();
        }
        if (!this._goal) {
            return;
        }
        this.goalSubscription = this.vchatApi.getGoalSubscription(this._goal);
        this.goalSubscription.addListener("change", () => {
            this._goalRest = this.goalSubscription.data;
        });
    }
    redirectAfterClose(closeReason) {
        if (!this.closeRedirect && !this.disconnectRedirect) {
            document.location.reload();
            return;
        }
        document.location.href = ["host_close", "user_close"].includes(closeReason)
            ? this.closeRedirect || this.disconnectRedirect
            : this.disconnectRedirect || this.closeRedirect;
    }
    isHost() {
        return this._isHost;
    }
    imBanned() {
        var _a, _b;
        return (_b = (_a = this.vchat.ban) === null || _a === void 0 ? void 0 : _a.some((b) => b.user === this.user)) !== null && _b !== void 0 ? _b : false;
    }
    isBanned(user) {
        var _a, _b;
        return (_b = (_a = this.vchat.ban) === null || _a === void 0 ? void 0 : _a.some((b) => b.user === user)) !== null && _b !== void 0 ? _b : false;
    }
    isViewer() {
        return !this._isHost;
    }
    toJSON() {
        return Object.assign(Object.assign({}, this.vchat), { host_session: this.hostSession, isHost: this._isHost, session: this.authentication.session.id, isActiveSession: this.isActiveSession() });
    }
}
