import { ConsoleSink } from "@aurelia/kernel";
import { Configuration as ConfigurationBase } from "@tawenda-npm/aurelia-plugin-live";
import { SentrySink } from "../sentry/SentrySink";
const config = {
    david_dev: {
        debug: true,
        environment: "staging",
        application: "vchat-staging",
        urls: {
            creditWallet: "https://google.fr/{vchat_id}",
            clock: "https://auth-staging.s.revma.io/",
            auth: "https://auth-staging.s.revma.io/1/",
            hub: "https://hub-staging.k-devops.c.revma.io/1/",
            links: "https://video-links.k-devops.c.revma.io/1/",
            sfu: "https://sfu-onl-01.tawenda-tech.org/1/",
            messaging: "https://messaging.k-devops.c.revma.io/1/",
            vchat: "https://vchat-api.k-devops.c.revma.io/1/",
            defaultRedirect: "https://www.google.fr",
        },
        messaging: {
            chunkSize: 50,
            service: "vchat",
        },
        capture: {
            width: 960,
            fps: 24,
        },
    },
    julien_dev: {
        debug: true,
        environment: "staging",
        application: "vchat-staging",
        urls: {
            creditWallet: "https://google.fr/{vchat_id}",
            clock: "https://auth-staging.s.revma.io/",
            auth: "https://auth-staging.s.revma.io/1/",
            hub: "https://hub-staging.k-devops.c.revma.io/1/",
            links: "https://video-links.k-devops.c.revma.io/1/",
            sfu: "https://sfu-0-staging.webrtc.revma.io/1/",
            messaging: "https://messaging.k-devops.c.revma.io/1/",
            vchat: "https://vchat-api.k-devops.c.revma.io/1/",
            defaultRedirect: "https://www.google.fr",
        },
        messaging: {
            chunkSize: 50,
            service: "vchat",
        },
        capture: {
            width: 960,
            fps: 24,
        },
    },
    vince_dev: {
        debug: true,
        environment: "staging",
        application: "vchat-staging",
        urls: {
            creditWallet: "https://google.fr/{vchat_id}",
            clock: "https://auth-staging.s.revma.io/",
            auth: "https://auth-staging.s.revma.io/1/",
            hub: "https://hub-staging.k-devops.c.revma.io/1/",
            links: "https://video-links.k-devops.c.revma.io/1/",
            sfu: "https://sfu-0.webrtc.revma.io/1/",
            messaging: "https://messaging.k-devops.c.revma.io/1/",
            vchat: "https://vchat-api.k-devops.c.revma.io/1/",
            defaultRedirect: "https://www.google.fr",
        },
        messaging: {
            chunkSize: 50,
            service: "vchat",
        },
        capture: {
            width: 960,
            fps: 24,
        },
    },
    staging: {
        debug: true,
        environment: "staging",
        application: "vchat-staging",
        urls: {
            creditWallet: "https://google.fr/{vchat_id}",
            clock: "https://auth-staging.s.revma.io/",
            auth: "https://auth-staging.s.revma.io/1/",
            hub: "https://hub-staging.k-devops.c.revma.io/1/",
            links: "https://video-links.k-devops.c.revma.io/1/",
            sfu: "https://sfu-0.webrtc.revma.io/1/",
            messaging: "https://messaging.k-devops.c.revma.io/1/",
            vchat: "https://vchat-api.k-devops.c.revma.io/1/",
            defaultRedirect: "https://www.google.fr",
        },
        messaging: {
            chunkSize: 50,
            service: "vchat",
        },
        logging: {
            level: 1 /* debug */,
            sentry: {
                dsn: "https://41e898c8b7dd4db3821dbbfd5928d4c9@sentry.tawenda-tech.org/103",
                level: 3 /* warn */,
            },
        },
        version: {
            sha: process.env.CI_COMMIT_SHA,
            tag: process.env.CI_COMMIT_TAG,
        },
        capture: {
            width: 960,
            fps: 24,
        },
    },
    swame_staging: {
        debug: true,
        environment: "production",
        application: "vchat-staging",
        urls: {
            creditWallet: "https://ef97a80e83d.office.swame.com/_/live/wallet/credit/{vchat_id}/",
            clock: "https://auth-staging.s.revma.io/",
            auth: "https://auth-staging.s.revma.io/1/",
            hub: "https://hub-staging.k-devops.c.revma.io/1/",
            links: "https://video-links.k-devops.c.revma.io/1/",
            sfu: "https://sfu-0.webrtc.revma.io/1/",
            messaging: "https://messaging.k-devops.c.revma.io/1/",
            vchat: "https://vchat-api-ef97a80e83d.k-devops.c.revma.io/1/",
            defaultRedirect: "https://www.google.fr",
        },
        messaging: {
            chunkSize: 50,
            service: "vchat",
        },
        logging: {
            level: 1 /* debug */,
            sentry: {
                dsn: "https://41e898c8b7dd4db3821dbbfd5928d4c9@sentry.tawenda-tech.org/103",
                level: 3 /* warn */,
            },
        },
        version: {
            sha: process.env.CI_COMMIT_SHA,
            tag: process.env.CI_COMMIT_TAG,
        },
        capture: {
            width: 960,
            fps: 24,
        },
    },
    swame_production: {
        debug: true,
        environment: "production",
        application: "mercifans",
        urls: {
            creditWallet: "https://swame.com/_/live/wallet/credit/{vchat_id}/",
            clock: "https://auth.s.revma.io/",
            auth: "https://auth.s.revma.io/1/",
            hub: "https://hub.s.revma.io/1/",
            links: "https://video-links.s.revma.io/1/",
            sfu: "https://sfu-0.webrtc.revma.io/1/",
            messaging: "https://messaging.s.revma.io/1/",
            vchat: "https://vchat-api.s.revma.io/1/",
            defaultRedirect: "https://swame.com",
        },
        messaging: {
            chunkSize: 50,
            service: "vchat",
        },
        logging: {
            level: 1 /* debug */,
            sentry: {
                dsn: "https://41e898c8b7dd4db3821dbbfd5928d4c9@sentry.tawenda-tech.org/103",
                level: 3 /* warn */,
            },
        },
        version: {
            sha: process.env.CI_COMMIT_SHA,
            tag: process.env.CI_COMMIT_TAG,
        },
        capture: {
            width: 960,
            fps: 24,
        },
    },
};
const hostsConfigs = {
    "vchat.julien.office.thetawenda.com": config.julien_dev,
    "vchat.k-devops.c.revma.io": config.staging,
    "vchat.pop.oxys.net": config.david_dev,
    "vchat.vince.u.office.thetawenda.com": config.vince_dev,
    "mercicam.pop.oxys.net": config.david_dev,
    "swame.anne.u.office.thetawenda.com": config.david_dev,
    "ef97a80e83d.office.swame.com": config.swame_staging,
    "swame.com": config.swame_production,
};
export class Configuration extends ConfigurationBase {
    constructor(config) {
        var _a, _b, _c;
        var _d;
        super(config);
        this.config.messages = Object.assign({ parsedDomains: ["swame.com"] }, config.messages);
        this._parsedDomainsRegexp = new RegExp(`(https://[a-z.]*(${this.config.messages.parsedDomains.join("|")})/?[^ ]*)`, "gi");
        this.config.editor = Object.assign({ maxSize: 250 }, config.editor);
        const sinks = [ConsoleSink];
        if ((_b = (_a = config.logging) === null || _a === void 0 ? void 0 : _a.sentry) === null || _b === void 0 ? void 0 : _b.dsn) {
            (_c = (_d = config.logging.sentry).level) !== null && _c !== void 0 ? _c : (_d.level = 3 /* warn */);
            sinks.push(SentrySink);
        }
        this.config.logging = Object.assign({ level: 1 /* debug */, sinks }, config.logging);
        this.config.version = Object.assign({ tag: "0.0.0", sha: "none" }, config.version);
    }
    static buildWithHost(host) {
        const config = hostsConfigs[host];
        if (!config) {
            throw new Error("this host has no configuration");
        }
        return new Configuration(config);
    }
    get environment() {
        return this.config.environment;
    }
    get messages() {
        return this.config.messages;
    }
    get editor() {
        return this.config.editor;
    }
    get logging() {
        return this.config.logging;
    }
    get version() {
        return this.config.version;
    }
    get parsedDomainsRegexp() {
        return this._parsedDomainsRegexp;
    }
}
