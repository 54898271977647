var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var Drawer_1;
import { customElement, bindable } from "aurelia";
import template from "./drawer.html";
import css from "./drawer.less";
import anime from "animejs";
let Drawer = Drawer_1 = class Drawer {
    constructor(el) {
        this.el = el;
        this._isDragged = false;
        this._isOpen = false;
        this.edge = "left";
        this.inDuration = 250;
        this.outDuration = 200;
        this.handleCloseTriggerClick = (e) => {
            this.close();
        };
        this.handleDragTargetDrag = (e) => {
            if (!this.isDragged) {
                this.startDrag(e);
            }
            this.dragMoveUpdate(e);
        };
        this.handleDragTargetRelease = (e) => {
            if (this.isDragged) {
                if (this.isOpen) {
                    if (this.percentOpen < 0.6) {
                        this.close();
                    }
                    else {
                        this.animateIn();
                    }
                }
                else {
                    if (this.percentOpen > 0.4) {
                        this.open();
                    }
                    else {
                        this.animateOut();
                    }
                }
                this.isDragged = false;
            }
        };
    }
    set isDragged(value) {
        this._isDragged = value;
        if (value) {
            Drawer_1.active = this;
        }
        else if (!this.isOpen) {
            Drawer_1.active = null;
        }
    }
    get isDragged() {
        return this._isDragged;
    }
    set isOpen(value) {
        this._isOpen = value;
        if (value) {
            Drawer_1.active = this;
        }
        else {
            Drawer_1.active = null;
        }
    }
    get isOpen() {
        return this._isOpen;
    }
    binding() {
        this.createDragTarget();
        this.createOverlay();
        this.setupEventHandlers();
    }
    createOverlay() {
        if (this.overlay) {
            return;
        }
        this.overlay = document.createElement("drawer-overlay");
        this.overlay.classList.add("drawer-overlay");
        this.el.parentElement.insertBefore(this.overlay, this.el);
    }
    createDragTarget() {
        if (this.dragTarget) {
            return;
        }
        this.dragTarget = document.createElement("drawer-drag-target");
        this.dragTarget.classList.add("drawer-drag-target", `drawer-drag-target--${this.edge}`);
        document.body.appendChild(this.dragTarget);
    }
    setupEventHandlers() {
        this.dragTarget.addEventListener("touchmove", this.handleDragTargetDrag);
        this.dragTarget.addEventListener("touchend", this.handleDragTargetRelease);
        this.dragTarget.addEventListener("click", this.handleDragTargetClick);
        this.overlay.addEventListener("click", this.handleCloseTriggerClick);
        this.el.addEventListener("touchmove", this.handleDragTargetDrag);
        this.el.addEventListener("touchend", this.handleDragTargetRelease);
    }
    handleDragTargetClick(e) {
        if (Drawer_1.active && Drawer_1.active !== this) {
            Drawer_1.active.handleCloseTriggerClick(e);
            e.preventDefault();
        }
    }
    startDrag(e) {
        if (Drawer_1.active && Drawer_1.active !== this) {
            return;
        }
        const startPosition = e.targetTouches[0].clientX;
        this.size = this.el.getBoundingClientRect().width;
        this.isDragged = true;
        this.overlay.style.display = "block";
        this.startPosition = startPosition;
    }
    dragMoveUpdate(e) {
        const now = Date.now();
        const position = e.targetTouches[0].clientX;
        this.position = position;
        this.lastEventTime = now;
        let totalDelta = this.position - this.startPosition;
        const dragDirection = totalDelta < 0 ? "right" : "left";
        totalDelta = Math.min(this.size, Math.abs(totalDelta));
        if ((!this.isOpen && this.edge !== dragDirection) || (this.isOpen && this.edge === dragDirection)) {
            totalDelta = 0;
        }
        this.percentOpen = (this.isOpen ? this.size - totalDelta : totalDelta) / this.size;
        let percentTransform = (1 - this.percentOpen) * -100;
        if (this.edge === "right") {
            percentTransform *= -1;
        }
        this.el.style.transform = `translateX(${percentTransform}%)`;
    }
    open() {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        this.animateIn();
    }
    close() {
        if (!this.isOpen) {
            return;
        }
        this.isOpen = false;
        this.animateOut();
    }
    animateIn() {
        this.animateDrawerIn();
        this.animateOverlayIn();
    }
    animateOut() {
        this.animateDrawerOut();
        this.animateOverlayOut();
    }
    animateDrawerOut() {
        const endPercent = this.edge === "left" ? -1 : 1;
        let slideOutPercent = 0;
        if (this.isDragged) {
            slideOutPercent = this.edge === "left" ? endPercent + this.percentOpen : endPercent - this.percentOpen;
        }
        anime.remove(this.el);
        anime({
            targets: this.el,
            translateX: [`${slideOutPercent * 100}%`, `${endPercent * 105}%`],
            duration: this.outDuration * this.percentOpen,
            easing: "easeOutQuad",
        });
    }
    animateDrawerIn() {
        let slideOutPercent = this.edge === "left" ? -1 : 1;
        if (this.isDragged) {
            slideOutPercent = this.edge === "left" ? slideOutPercent + this.percentOpen : slideOutPercent - this.percentOpen;
        }
        anime.remove(this.el);
        anime({
            targets: this.el,
            translateX: [`${slideOutPercent * 100}%`, 0],
            duration: this.inDuration * this.percentOpen,
            easing: "easeOutQuad",
        });
    }
    animateOverlayIn() {
        let start = 0;
        if (this.isDragged) {
            start = this.percentOpen;
        }
        else {
            this.overlay.style.display = "block";
        }
        anime.remove(this.overlay);
        anime({
            targets: this.overlay,
            opacity: [start, 1],
            duration: this.inDuration,
            easing: "easeOutQuad",
        });
    }
    animateOverlayOut() {
        anime.remove(this.overlay);
        anime({
            targets: this.overlay,
            opacity: 0,
            duration: this.outDuration,
            easing: "easeOutQuad",
            complete: () => {
                this.overlay.style.display = "none";
            },
        });
    }
};
__decorate([
    bindable,
    __metadata("design:type", String)
], Drawer.prototype, "edge", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], Drawer.prototype, "inDuration", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], Drawer.prototype, "outDuration", void 0);
Drawer = Drawer_1 = __decorate([
    customElement({
        name: "drawer",
        template: template,
        dependencies: [css],
    }),
    __metadata("design:paramtypes", [HTMLElement])
], Drawer);
export default Drawer;
