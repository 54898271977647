<template class="app-panels panel ${css}">
  <div class="app-panels__row app-panels__row--aside">
    <app-leave-vchat></app-leave-vchat>
    <app-fullscreen class="app-fullscreen"></app-fullscreen>
    <app-sound class="app-sound app-sound--viewer"></app-sound>

    <div class="panel__el panel__el--first panel__el--flex panel__el--always">
      <section class="app-panels__user">
        <app-host-avatar></app-host-avatar>
        <span class="app-panels__name"><user-display id.bind="host"></user-display></span>
      </section>
      <button class="app-panels__toggle" click.trigger="toggle()">${toggleButtonText | t}</button>
    </div>

    <app-goal if.bind="goal" class="panel__el"></app-goal>
    <app-top-tip class="panel__el"></app-top-tip>
  </div>
  <div class="app-panels__row">
    <app-swipe-tutorial swipe-done.bind="leftSwipeDiscovered"></app-swipe-tutorial>
    <app-chat class="app-panels__chat panel__el panel__el--fill"></app-chat>
  </div>
</template>
