var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import "./app-root.less";
import { customElement, route, inject, IRouter, ILogger } from "aurelia";
import template from "./app-root.html";
import { VchatService } from "./services/VchatService";
import { Configuration } from "./services/Configuration";
import AppError from "./app-error";
import { DialogService, IDialogService } from "./dialog";
import AppSettings from "./components/dialogs/app-settings";
import { InsufficientFundsError } from "./services/VchatApi";
import { AppState } from "./services/AppState";
import { DebugTools } from "./services/DebugTools";
import { GoalService } from "./services/GoalService";
import { VchatClosedError, VchatNotFoundError } from "./vchat/Vchat";
import { Sound } from "./services/Sound";
import { noop } from "@tawenda-npm/aurelia-plugin-live";
import { VchatStatistics } from "./services/VchatStatistics";
import { StreamingQualityService } from "./services/StreamingQualityService";
let AppRoot = class AppRoot {
    constructor(router, vchatService, vchatStatistics, appState, configuration, goalService, streamingQualityService, // Do not remove
    sound, dialogService, defaultLogger, debugTools) {
        this.router = router;
        this.vchatService = vchatService;
        this.vchatStatistics = vchatStatistics;
        this.appState = appState;
        this.configuration = configuration;
        this.goalService = goalService;
        this.streamingQualityService = streamingQualityService;
        this.sound = sound;
        this.dialogService = dialogService;
        this.defaultLogger = defaultLogger;
        this.debugTools = debugTools;
        this.activeTab = "center";
        this.animateGoal = (first) => {
            var _a;
            (_a = this.goalAnimation) === null || _a === void 0 ? void 0 : _a.startAnimate();
            if (first) {
                // this.sound.play("goal-completed").catch(noop);
            }
        };
        this.isLoaded = false;
        this.logger = this.defaultLogger.scopeTo(`AppRoot`);
    }
    bound() {
        this.goalService.onComplete = this.animateGoal;
        this.vchatStatistics.on("tip", () => this.sound.play("tip").catch(noop));
    }
    async binding() {
        var _a;
        document.vchat = { debugTools: this.debugTools };
        try {
            if (!this.vchatId) {
                if (this.configuration.environment !== "production") {
                    await this.vchatService.create();
                }
                else {
                    throw new Error("Missing vchat id");
                }
            }
            else {
                await this.vchatService.load(this.vchatId);
            }
            this.isLoaded = true;
        }
        catch (e) {
            this.logger.error(e);
            if (e instanceof InsufficientFundsError || e instanceof VchatClosedError) {
                document.location.href = ((_a = this.vchatService.vchat) === null || _a === void 0 ? void 0 : _a.closeRedirect) || this.configuration.urls.defaultRedirect;
                return;
            }
            if (e instanceof VchatNotFoundError) {
                document.location.href = this.configuration.urls.defaultRedirect;
                return;
            }
            this.router.load(AppError).catch(() => {
                document.location.href = this.configuration.urls.defaultRedirect;
            });
        }
    }
    async settings() {
        const { controller } = await this.dialogService.open({
            component: () => AppSettings,
            keyboard: "Escape",
            overlayDismiss: true,
        });
        await controller.closed;
    }
    async canLoad(params) {
        var _a;
        this.vchatId = ((_a = document.head.querySelector("meta[name=vchat-id]")) === null || _a === void 0 ? void 0 : _a.getAttribute("content")) || params.vchat;
        return true;
    }
    get css() {
        const cls = [`app-root--${this.appState.type}`];
        if (this.appState.previewActive) {
            cls.push(...["app-root--active-camera", `app-root--${this.appState.type}-active-camera`]);
        }
        if (this.appState.activeSession) {
            cls.push(...["app-root--active-session", `app-root--${this.appState.type}-active-session`]);
        }
        else {
            cls.push(...["app-root--inactive-session", `app-root--${this.appState.type}-inactive-session`]);
        }
        cls.push(`app-root--active-${this.activeTab}`);
        return cls.join(" ");
    }
};
AppRoot = __decorate([
    inject(),
    customElement({ name: "app-root", template }),
    __param(0, IRouter),
    __param(8, IDialogService),
    __param(9, ILogger),
    __metadata("design:paramtypes", [Object, VchatService,
        VchatStatistics,
        AppState,
        Configuration,
        GoalService,
        StreamingQualityService,
        Sound,
        DialogService, Object, DebugTools])
], AppRoot);
export { AppRoot };
let AppInit = class AppInit {
};
AppInit = __decorate([
    route({
        routes: [
            { path: "/:lang/_/live/:any", component: AppRoot },
            { path: "/:vchat", component: AppRoot },
            { path: "", component: AppRoot },
            { id: "app-error", path: "/error", component: AppError },
        ],
    }),
    customElement({ name: "app-init", template: "<au-viewport></au-viewport>" })
], AppInit);
export { AppInit };
