var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { bindable, customElement } from "aurelia";
import template from "./message.html";
import css from "./message.less";
import { UsersRegistryService } from "../../services/UsersRegistryService";
import { isEmoji } from "../../libs/emojis";
import { Configuration } from "../../services/Configuration";
import { DialogService, IDialogService } from "../../dialog";
import AppMessageMenu from "./app-message-menu";
import { VchatService } from "../../services/VchatService";
import { AppState } from "../../services/AppState";
const isUpperCase = (str) => str && str === str.toUpperCase();
const parser = new DOMParser();
let MessageComponent = class MessageComponent {
    constructor(usersRegistryService, configuration, vchatService, appState, dialogService) {
        this.usersRegistryService = usersRegistryService;
        this.configuration = configuration;
        this.vchatService = vchatService;
        this.appState = appState;
        this.dialogService = dialogService;
    }
    binding() {
        this.isText = this.message.type === "text";
        this.isTip = this.message.type === "tip";
        this.isPreset = this.message.type === "preset";
        this.user = this.isTip ? this.content.user : this.message.user;
        this._user = this.usersRegistryService.get(this.user);
    }
    get content() {
        if (this.isTip) {
            return JSON.parse(this.message.content);
        }
        let content = this.message.content.replace(this.configuration.parsedDomainsRegexp, '<a href="$1">$1</a>');
        try {
            const xmlDoc = parser.parseFromString(content, "text/html");
            xmlDoc.body.childNodes.forEach((p) => {
                if (p.nodeName === "#text" && isUpperCase(p.innerText)) {
                    p.innerText = p.innerText.toLowerCase();
                }
                else if (isUpperCase(p.textContent)) {
                    p.textContent = p.textContent.toLowerCase();
                }
            });
            content = xmlDoc.body.innerHTML;
        }
        catch (e) {
            // ignore
        }
        return content;
    }
    async click(event) {
        if (this.message.user === this.vchatService.vchat.host ||
            (this.vchatService.vchat.isViewer() && !this.vchatService.vchat.isAdmin)) {
            return;
        }
        event.preventDefault();
        const { controller } = await this.dialogService.open({
            component: () => AppMessageMenu,
            model: this.message,
            keyboard: "Escape",
            overlayDismiss: true,
        });
        await controller.closed;
    }
    get class() {
        return this.isText && isEmoji(this.message.content) ? "emoji" : "";
    }
    get isBan() {
        return this.vchatService.vchat.isBanned(this.user);
    }
    get css() {
        return [
            "message",
            this.isTip ? "message--tip" : "message--text",
            this.isBan ? "message--ban" : "",
            this._user.host ? "message--host" : this._user.fan ? "message--fan" : "",
        ].join(" ");
    }
};
__decorate([
    bindable,
    __metadata("design:type", Object)
], MessageComponent.prototype, "message", void 0);
MessageComponent = __decorate([
    customElement({ name: "message", template, dependencies: [css] }),
    __param(4, IDialogService),
    __metadata("design:paramtypes", [UsersRegistryService,
        Configuration,
        VchatService,
        AppState,
        DialogService])
], MessageComponent);
export { MessageComponent };
