<template class="app-stream-quality">
  <template if.bind="isActive">
    <progress-bar
      class="app-stream-quality__progress-bar app-stream-quality__progress-bar--${qualityClass}"
      progress.bind="quality"
      background.bind="backgroundStyle"
    >
      <div au-slot class="app-stream-quality__progress-bar-content">
        <span class="app-stream-quality__subtitle">Signal</span>
        <span class="app-stream-quality__title">${qualityLabel | t}</span>
      </div>
    </progress-bar>
  </template>
</template>
