var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { inject } from "aurelia";
import { Authentication as AuthenticationBase } from "@tawenda-npm/aurelia-plugin-live/lib/Authentication";
import { Configuration } from "./Configuration";
import { LinksService, MediaDeviceService, SfuRegistries } from "@tawenda-npm/aurelia-plugin-live";
let DebugTools = class DebugTools {
    constructor(auth, configuration, linksService, mediaDeviceService, sfuRegistries) {
        this.auth = auth;
        this.configuration = configuration;
        this.linksService = linksService;
        this.mediaDeviceService = mediaDeviceService;
        this.sfuRegistries = sfuRegistries;
    }
    setResolution(width) {
        // hash captureWith is broken
        this.configuration.capture.width = width;
        this.mediaDeviceService.captureWidth = Math.random();
    }
};
DebugTools = __decorate([
    inject(),
    __metadata("design:paramtypes", [AuthenticationBase,
        Configuration,
        LinksService,
        MediaDeviceService,
        SfuRegistries])
], DebugTools);
export { DebugTools };
