var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger, watch } from "aurelia";
import { VchatService } from "../../services/VchatService";
import template from "./app-last-tippers.html";
import css from "./app-last-tippers.less";
import { VchatStatistics } from "../../services/VchatStatistics";
let AppLastTippers = class AppLastTippers {
    constructor(vchatService, statistics, defaultLogger) {
        this.vchatService = vchatService;
        this.statistics = statistics;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppLastTippers`);
        if (!this.vchatService.vchat.isHost()) {
            throw new Error("this widget is reserved to the host");
        }
        this.vchat = this.vchatService.vchat;
        this.updateList();
    }
    updateList() {
        this.list = this.statistics.lastTips;
    }
};
__decorate([
    watch("statistics.lastTips"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppLastTippers.prototype, "updateList", null);
AppLastTippers = __decorate([
    customElement({
        name: "app-last-tippers",
        template: template,
        dependencies: [css],
    }),
    __param(2, ILogger),
    __metadata("design:paramtypes", [VchatService,
        VchatStatistics, Object])
], AppLastTippers);
export default AppLastTippers;
