var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var AppState_1;
import { inject } from "aurelia";
import EventEmitter from "events";
var StorageKey;
(function (StorageKey) {
    StorageKey["soundMuted"] = "sound-muted";
})(StorageKey || (StorageKey = {}));
let AppState = AppState_1 = class AppState extends EventEmitter {
    constructor() {
        super();
        this._muted = false;
        this.activeSession = false;
        this.previewActive = false;
        this._fullscreen = false;
        this.fullscreenChange = () => {
            if (this._fullscreen !== !!document.fullscreenElement) {
                this._fullscreen = !!document.fullscreenElement;
                this.emit("fullscreenChange", { value: !!document.fullscreenElement });
            }
        };
        this._muted = sessionStorage.getItem(this.storageKey(StorageKey.soundMuted)) === "true";
        document.addEventListener("fullscreenchange", this.fullscreenChange);
    }
    storageKey(key) {
        return `vchat-app-state-${AppState_1.settingsVersion}-${key}`;
    }
    get host() {
        return this._type === "host";
    }
    get viewer() {
        return this._type === "viewer";
    }
    set type(value) {
        this._type = value;
    }
    get type() {
        return this._type;
    }
    set muted(value) {
        this._muted = value;
        sessionStorage.setItem(this.storageKey(StorageKey.soundMuted), value ? "true" : "false");
        this.emit("mutedChange", { value });
    }
    get muted() {
        return this._muted;
    }
    set fullscreen(value) {
        this._fullscreen = value;
        if (value) {
            document
                .getElementsByTagName("app-init")[0]
                .requestFullscreen({ navigationUI: "hide" })
                .finally(this.fullscreenChange);
        }
        else {
            document.exitFullscreen().finally(this.fullscreenChange);
        }
    }
    get fullscreen() {
        return this._fullscreen;
    }
};
AppState.settingsVersion = 1;
AppState = AppState_1 = __decorate([
    inject(),
    __metadata("design:paramtypes", [])
], AppState);
export { AppState };
