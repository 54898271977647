var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement } from "aurelia";
import template from "./app-sound.html";
import css from "./app-sound.less";
import { AppState } from "../../services/AppState";
import { VchatService } from "../../services/VchatService";
let AppSound = class AppSound {
    constructor(appState, vchatService) {
        this.appState = appState;
        this.vchatService = vchatService;
    }
    get type() {
        return this.vchatService.vchat.isHost() ? "host" : "viewer";
    }
    get icon() {
        return this.vchatService.vchat.isViewer() ? "volume" : "microphone";
    }
    get muted() {
        return this.appState.muted;
    }
    set muted(value) {
        this.appState.muted = value;
    }
};
AppSound = __decorate([
    customElement({ name: "app-sound", template, dependencies: [css] }),
    __metadata("design:paramtypes", [AppState, VchatService])
], AppSound);
export default AppSound;
