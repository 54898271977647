var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ILogger, inject } from "aurelia";
import { Authentication } from "./Authentication";
import { HttpError } from "@tawenda-npm/auth-js-browser";
import { SynchronizedArrayFactory, SynchronizedObjectFactory, } from "@tawenda-npm/aurelia-plugin-live";
import { Configuration } from "./Configuration";
export class InsufficientFundsError extends Error {
}
let VchatApi = class VchatApi {
    constructor(authentication, configuration, synchronizedArrayFactory, synchronizedObjectFactory, defaultLogger) {
        this.authentication = authentication;
        this.configuration = configuration;
        this.synchronizedArrayFactory = synchronizedArrayFactory;
        this.synchronizedObjectFactory = synchronizedObjectFactory;
        this.defaultLogger = defaultLogger;
        this.logger = defaultLogger.scopeTo(`VchatApi`);
        this.prefixUrl = this.configuration.urls.vchat;
        this.ready = this.authentication.ready.then(() => {
            this.client = this.authentication.sessionClient.extend({
                prefixUrl: this.prefixUrl,
            });
        });
    }
    async create(data = {}) {
        const payload = Object.assign({ host: this.authentication.user, app: this.configuration.application, access: "all", price: 400, share: 0.8, point_value: 1.5, pay: "all" }, data);
        return await this.client
            .post("vchats/", {
            body: JSON.stringify(payload),
        })
            .json();
    }
    async getVchat(id) {
        try {
            return await this.client.get(`vchats/${id}`).json();
        }
        catch (err) {
            if (err instanceof HttpError && err.response.status === 404) {
                return void 0;
            }
            throw err;
        }
    }
    getVchatSubscription(id) {
        return this.synchronizedObjectFactory.build(`${this.prefixUrl}vchats/${id}`, this.defaultLogger.scopeTo("Vchat Subscription"));
    }
    getUserPreferencesSubscription() {
        const user = this.authentication.user;
        return this.synchronizedObjectFactory.build(`${this.prefixUrl}users/${user}/preferences`, this.defaultLogger.scopeTo("Preferences Subscription"));
    }
    async setUserPreferencesSubscription(key, value) {
        const user = this.authentication.user;
        await this.client.put(`users/${user}/preferences/${key}`, {
            body: JSON.stringify({ value }),
        });
    }
    getVchatStatsSubscription(id) {
        return this.synchronizedObjectFactory.build(`${this.prefixUrl}vchats/${id}/stats`, this.defaultLogger.scopeTo("VchatStats Subscription"));
    }
    async postTip(vchat, data) {
        try {
            return await this.client
                .post(`vchats/${vchat}/tip`, {
                body: JSON.stringify(data),
            })
                .json();
        }
        catch (err) {
            if (err instanceof HttpError && err.response.status === 409) {
                throw new InsufficientFundsError();
            }
            throw err;
        }
    }
    async ban(vchat, user) {
        await this.client.put(`vchats/${vchat}/users/${user}/ban`);
    }
    async open(vchat) {
        await this.client.put(`vchats/${vchat}/open`);
    }
    async log(vchat, type, data) {
        const session = this.authentication.session.id;
        const user = this.authentication.user;
        const date = new Date().toISOString();
        await this.client
            .post(`vchats/${vchat}/users/${user}/sessions/${session}/logs/`, {
            body: JSON.stringify({ date, data, type }),
        })
            .catch((err) => this.logger.error(err));
    }
    async setHostSession(vchat, force) {
        await this.client.put(`vchats/${vchat}/host-session`, {
            body: JSON.stringify({
                session: this.authentication.session.id,
                force: !!force,
            }),
        });
    }
    async closeVchat(vchat) {
        try {
            await this.client.put(`vchats/${vchat}/closed`);
        }
        catch (err) {
            if (!(err instanceof HttpError) || err.response.status !== 409) {
                throw err;
            }
        }
    }
    async leave(vchat) {
        await this.client.delete(`vchats/${vchat}/users/${this.authentication.user}`);
    }
    async join(vchat, force) {
        try {
            const response = await this.client.put(`vchats/${vchat}/users/${this.authentication.user}`, {
                body: JSON.stringify({
                    session: this.authentication.session.id,
                    force: !!force,
                }),
            });
            return await response.json();
        }
        catch (err) {
            if (err instanceof HttpError && err.response.status === 409) {
                throw new InsufficientFundsError();
            }
            throw err;
        }
    }
    async getVchatHostAvatar(vchat) {
        const { url } = await this.client.get(`vchats/${vchat}/avatar`).json();
        return url;
    }
    getVchatUsersSubscription(vchat) {
        return this.synchronizedArrayFactory.build(`${this.prefixUrl}vchats/${vchat}/users/`, (docA, docB) => docA.id === docB.id, this.defaultLogger.scopeTo("Vchat users Subscription"));
    }
    getVchatUserSubscription(vchat) {
        return this.synchronizedArrayFactory.build(`${this.prefixUrl}vchats/${vchat}/users/${this.authentication.user}/sessions/`, (docA, docB) => docA.id === docB.id, this.defaultLogger.scopeTo("VchatUser Subscription"));
    }
    async addGoal(vchat, title, target) {
        return await this.client.put(`vchats/${vchat}/goal`, { body: JSON.stringify({ title, target }) }).json();
    }
    async deactivateGoal(vchat) {
        await this.client.delete(`vchats/${vchat}/goal`);
    }
    getGoalSubscription(id) {
        return this.synchronizedObjectFactory.build(`${this.prefixUrl}goals/${id}`, this.defaultLogger.scopeTo("Goal Subscription"));
    }
};
VchatApi = __decorate([
    inject(),
    __param(4, ILogger),
    __metadata("design:paramtypes", [Authentication,
        Configuration,
        SynchronizedArrayFactory,
        SynchronizedObjectFactory, Object])
], VchatApi);
export { VchatApi };
