var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import { DialogController } from "../../../dialog";
import template from "./app-message-menu.html";
import css from "./app-message-menu.less";
import { VchatService } from "../../../services/VchatService";
import { VchatApi } from "../../../services/VchatApi";
let AppMessageMenu = class AppMessageMenu {
    constructor(dialogController, vchatApi, vchatService, defaultLogger) {
        this.dialogController = dialogController;
        this.vchatApi = vchatApi;
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppMessageMenu`);
    }
    activate(model) {
        this.model = model;
    }
    get user() {
        return this.model.user;
    }
    ban() {
        this.vchatApi.ban(this.vchatService.vchat.id, this.user);
        this.dialogController.ok();
    }
    close() {
        this.dialogController.ok();
    }
};
AppMessageMenu = __decorate([
    customElement({ name: "app-errorapp-message-menu", template: template, dependencies: [css] }),
    __param(3, ILogger),
    __metadata("design:paramtypes", [DialogController,
        VchatApi,
        VchatService, Object])
], AppMessageMenu);
export default AppMessageMenu;
