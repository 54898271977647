<template class="app-stream app-stream--${orientation} ${css} ${!isActiveSession ? 'app-stream--inactive' : ''}">
  <template if.bind="!isActiveSession">
    <app-activate-session class="app-stream__activate-session"></app-activate-session>
  </template>
  <button
    if.bind="!appState.previewActive"
    class="app-stream__preview-active-toggle"
    click.delegate="togglePreviewActive()"
  >
    ${"video.activateCamera" | t}
  </button>

  <div class="app-stream__video-wrapper">
    <video playsinline class="app-stream__video-background"></video>
    <video playsinline class="app-stream__video-top"></video>
    <div ref="stats" class="app-stream__stats">Video size: ${video.videoWidth}x${video.videoHeight}</div>
    <app-stream-quality class="app-stream__quality" if.bind="vchat.isHost()"></app-stream-quality>
  </div>
</template>
