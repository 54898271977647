import { MessageComponent } from "./message/message";
import AppChat from "./app-chat";
import AppStream from "./app-stream";
import AppPanelDesktop from "./app-panels";
import AppGoal from "./app-goal";
import AppTips from "./app-tips";
import AppTopTip from "./app-top-tip";
import AppTopTippers from "./app-top-tippers";
import AppLastTippers from "./app-last-tippers";
import AppWidgets from "./app-widgets";
import AppCloseVchat from "./app-close-vchat";
import AppLeaveVchat from "./app-leave-vchat";
import AppHostAvatar from "./app-host-avatar";
import AppSound from "./app-sound";
import AppLowWalletAlert from "./app-low-wallet-alert";
import AppSwipeTutorial from "./app-swipe-tutorial";
import AppFullscreen from "./app-fullscreen";
import AppNavbar from "./app-navbar/app-navbar";
import AppActivateSession from "./app-activate-session";
import AppStreamQuality from "./app-stream-quality";
import AppSwitchCamera from "./app-switch-camera";
import AppPresetMessage from "./app-preset-message";
export const Producer = {
    register: (container) => {
        container.register(AppStream, MessageComponent, AppChat, AppPanelDesktop, AppGoal, AppTips, AppTopTip, AppTopTippers, AppLastTippers, AppWidgets, AppCloseVchat, AppLeaveVchat, AppHostAvatar, AppSound, AppFullscreen, AppNavbar, AppActivateSession, AppLowWalletAlert, AppSwipeTutorial, AppStreamQuality, AppSwitchCamera, AppPresetMessage);
    },
};
