var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { LinksService, MediaDeviceService, noop } from "@tawenda-npm/aurelia-plugin-live";
import { ILogger, inject } from "aurelia";
import { VchatApi } from "./VchatApi";
import { Authentication } from "./Authentication";
import { VchatClosedError, VchatNotFoundError } from "../vchat/Vchat";
import { HttpError } from "@tawenda-npm/auth-js-browser";
import { VchatFactory } from "../vchat/VchatFactory";
import { Configuration } from "./Configuration";
const Kbps = 1000;
const Mbps = 1000 * Kbps;
let VchatService = class VchatService {
    constructor(vchatApi, configuration, mediaDeviceService, authentication, vchatFactory, linksService, defaultLogger) {
        this.vchatApi = vchatApi;
        this.configuration = configuration;
        this.mediaDeviceService = mediaDeviceService;
        this.authentication = authentication;
        this.vchatFactory = vchatFactory;
        this.linksService = linksService;
        this.resolve = noop;
        this.logger = defaultLogger.scopeTo("VchatService");
        this.ready = new Promise((resolve) => (this.resolve = resolve));
    }
    async create() {
        if (this.configuration.environment !== "production") {
            try {
                await this.vchatApi.ready;
                const { id } = await this.vchatApi.create({
                    close_redirect: "https://swame.com/",
                    disconnect_redirect: "https://google.com/",
                    price: 20,
                    share: 0.8,
                    point_value: 0.15,
                });
                await this.vchatApi.open(id);
                document.location.pathname = id;
            }
            catch (err) {
                if (err instanceof HttpError && err.response.status === 409) {
                    this.authentication.resetUser();
                    document.location.reload();
                }
                throw err;
            }
        }
        else {
            throw new Error("development only");
        }
    }
    async load(id) {
        await this.vchatApi.ready;
        const vchatRest = await this.vchatApi.getVchat(id);
        if (!vchatRest) {
            throw new VchatNotFoundError();
        }
        if (vchatRest.status === "closed") {
            throw new VchatClosedError();
        }
        if (vchatRest.sfu) {
            this.linksService.sfu = this.configuration.get("urls.sfu") || vchatRest.sfu;
        }
        this.linksService.rtpEncodingParameters = [
            { scaleResolutionDownBy: 4, maxBitrate: 0.5 * Mbps, scalabilityMode: "S1T3" },
            { scaleResolutionDownBy: 2, maxBitrate: 0.7 * Mbps, scalabilityMode: "S1T3" },
            { scaleResolutionDownBy: 1, maxBitrate: 1 * Mbps, scalabilityMode: "S1T3" },
        ];
        this.vchat = this.vchatFactory.build(vchatRest);
        await this.vchat.ready;
        this.resolve();
    }
};
VchatService = __decorate([
    inject(),
    __param(6, ILogger),
    __metadata("design:paramtypes", [VchatApi,
        Configuration,
        MediaDeviceService,
        Authentication,
        VchatFactory,
        LinksService, Object])
], VchatService);
export { VchatService };
