var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { noop, SfuRegistries, sleep } from "@tawenda-npm/aurelia-plugin-live";
import { ILogger } from "aurelia";
import { VchatApi } from "./VchatApi";
import { VchatService } from "./VchatService";
const RECOMPUTE_QUALITY_INTERVAL = 2000;
const SEND_QUALITY_INTERVAL = 10000;
const SEND_QUALITY_THRESHOLD = 0.05;
let StreamingQualityService = class StreamingQualityService {
    constructor(defaultLogger, sfuRegistries, vchatService, vchatApi) {
        this.sfuRegistries = sfuRegistries;
        this.vchatService = vchatService;
        this.vchatApi = vchatApi;
        this.quality = 0;
        this.lastSentQuality = 0;
        this.loop = 0;
        this.refreshQuality().catch(noop);
        this.logger = defaultLogger.scopeTo(`StreamingQualityService`);
    }
    async refreshQuality() {
        try {
            let transport;
            if (this.vchatService.vchat.isHost()) {
                transport = await this.sfuRegistries.sendTransport.getById("default");
            }
            else {
                transport = await this.sfuRegistries.receiveTransport.getById("default");
            }
            if (transport) {
                await transport.refreshQuality();
                this.quality = transport.quality;
            }
            else {
                this.quality = 0;
            }
            this.logQuality();
        }
        catch (e) {
            this.logger.error(e);
            this.quality = 0;
        }
        finally {
            await sleep(RECOMPUTE_QUALITY_INTERVAL);
            this.refreshQuality().catch(noop);
        }
    }
    logQuality() {
        this.loop++;
        if (Math.abs(this.quality - this.lastSentQuality) > SEND_QUALITY_THRESHOLD &&
            this.loop % Math.ceil(SEND_QUALITY_INTERVAL / RECOMPUTE_QUALITY_INTERVAL) === 0) {
            this.vchatApi.log(this.vchatService.vchat.id, "video-quality", { quality: this.quality }).catch(noop);
            this.lastSentQuality = this.quality;
        }
    }
};
StreamingQualityService = __decorate([
    __param(0, ILogger),
    __metadata("design:paramtypes", [Object, SfuRegistries,
        VchatService,
        VchatApi])
], StreamingQualityService);
export { StreamingQualityService };
