<template class="app-chat ${css}">
  <template if.bind="!isViewer">
    <header class="dashboard-panel__header">${"chat.host_title" | t}</header>
  </template>
  <section ref="messagesList" class="app-chat__messages">
    <message class="app-chat__message" repeat.for="message of messages" message.bind="message"></message>
  </section>
  <template if.bind="imBanned">
    <section class="app-chat__editor ${isEditorOpen|toClass:'app-chat__editor--open'">${"chat.ban_alert" | t}</section>
  </template>
  <template else>
    <section class="app-chat__editor ${isEditorOpen|toClass:'app-chat__editor--open'">
      <button if.bind="!isEditorOpen" class="app-chat__open-editor" click.delegate="openEditor($event)">
        open-editor
      </button>
      <div class="app-chat__message-editor ${isEditorOpen|toClass:'app-chat__message-editor--open'">
        <message-editor
          blur.delegate="messageEditorBlur($event)"
          ref="messageEditor"
          value.bind="input"
          send.delegate="sendMessage($event)"
          blur-on-send.bind="blurEditorOnSend"
        ></message-editor>
      </div>
    </section>
  </template>
</template>
