var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { bindable, customElement, ILogger, watch } from "aurelia";
import template from "./app-swipe-tutorial.html";
import { VchatService } from "../../services/VchatService";
import css from "./app-swipe-tutorial.less";
import anime from "animejs";
let AppActivateSession = class AppActivateSession {
    constructor(vchatService, defaultLogger) {
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.localStorageKey = "hide-swipe-tutorial";
        if (!this.vchatService.vchat.isViewer()) {
            throw new Error("this widget is reserved to viewers");
        }
        this.logger = this.defaultLogger.scopeTo(`AppSwipeTutorial`);
        this.show = localStorage.getItem(this.localStorageKey) !== "1";
    }
    onSwipeDone() {
        if (this.swipeDone) {
            localStorage.setItem(this.localStorageKey, "1");
            this.show = false;
        }
    }
    attached() {
        if (!this.show) {
            return;
        }
        const path = anime.path(".swipe-tips__path path");
        anime({
            targets: ".swipe-tips__hand",
            translateX: path("x"),
            translateY: path("y"),
            rotate: path("angle"),
            easing: "easeInOutExpo",
            direction: "alternate",
            duration: 2000,
            loop: true,
        });
        anime({
            targets: ".swipe-tips__dot",
            opacity: 0.5,
            easing: "easeInOutQuint",
            direction: "alternate",
            duration: 1000,
            loop: true,
        });
    }
};
__decorate([
    bindable,
    __metadata("design:type", Boolean)
], AppActivateSession.prototype, "swipeDone", void 0);
__decorate([
    watch("swipeDone"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppActivateSession.prototype, "onSwipeDone", null);
AppActivateSession = __decorate([
    customElement({ name: "app-swipe-tutorial", template, dependencies: [css] }),
    __param(1, ILogger),
    __metadata("design:paramtypes", [VchatService, Object])
], AppActivateSession);
export default AppActivateSession;
