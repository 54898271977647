var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var PresetMessage_1;
import { customElement, bindable } from "aurelia";
import template from "./preset-message.html";
import css from "./preset-message.less";
const escapeHTML = (str) => {
    const p = document.createElement("p");
    p.appendChild(document.createTextNode(str));
    return p.innerHTML;
};
let PresetMessage = PresetMessage_1 = class PresetMessage {
    constructor() {
        this.content = "";
    }
    get html() {
        return PresetMessage_1.template(this.content);
    }
    static template(data) {
        let isInDl = false;
        return data
            .split("\n")
            .map((line, i, all) => {
            line = escapeHTML(line);
            const isLast = i === all.length - 1;
            if (line.match(/\s*([0-9]+)--\s+(.*)\s*/)) {
                line = line.trim();
                const match = line.match(/\s*([0-9]+)--\s+(.*)\s*/);
                line = `<dt>${match[2]}</dt><dd>${match[1]}<i class="icon-token"></i></dd>`;
                if (!isInDl) {
                    isInDl = true;
                    line = "<dl>" + line;
                }
            }
            else {
                if (isInDl) {
                    isInDl = false;
                    line = "</dl>" + line;
                }
                if (!isLast) {
                    line += "<br/>";
                }
            }
            if (isInDl && isLast) {
                line = line + "</dl>";
            }
            return line;
        })
            .join("")
            .replace("<br/><dl>", "<dl>");
    }
};
__decorate([
    bindable,
    __metadata("design:type", Object)
], PresetMessage.prototype, "content", void 0);
PresetMessage = PresetMessage_1 = __decorate([
    customElement({
        name: "preset-message",
        template: template,
        dependencies: [css],
    })
], PresetMessage);
export default PresetMessage;
