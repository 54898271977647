var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger, watch } from "aurelia";
import template from "./app-top-tip.html";
import css from "./app-top-tip.less";
import { VchatService } from "../../services/VchatService";
import { UsersRegistryService } from "../../services/UsersRegistryService";
let AppTopTip = class AppTopTip {
    constructor(element, vchatService, usersRegistryService, defaultLogger) {
        this.element = element;
        this.vchatService = vchatService;
        this.usersRegistryService = usersRegistryService;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppTopTip`);
        this.vchat = this.vchatService.vchat;
        this.initialCss = [...this.element.classList.values()];
        this.updateTopTip();
    }
    get isVisible() {
        return this._isVisible;
    }
    set isVisible(value) {
        var _a;
        if (value === this._isVisible) {
            return;
        }
        this._isVisible = value;
        if (!value) {
            this.css = (_a = this.initialCss) === null || _a === void 0 ? void 0 : _a.map((cls) => `${cls}--hidden`).join(" ");
        }
        else {
            this.css = "";
        }
    }
    updateTopTip() {
        if (this.vchat.topTip) {
            this.user = this.usersRegistryService.get(this.vchat.topTip.user);
            this.amount = this.vchat.topTip.amount;
            this.isVisible = true;
        }
        else {
            this.isVisible = false;
        }
    }
};
__decorate([
    watch("vchat.topTip"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppTopTip.prototype, "updateTopTip", null);
AppTopTip = __decorate([
    customElement({
        name: "app-top-tip",
        template,
        dependencies: [css],
    }),
    __param(3, ILogger),
    __metadata("design:paramtypes", [HTMLElement,
        VchatService,
        UsersRegistryService, Object])
], AppTopTip);
export default AppTopTip;
