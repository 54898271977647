var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, watch, ILogger } from "aurelia";
import { DialogController } from "../../../dialog";
import { VchatService } from "../../../services/VchatService";
import template from "./app-settings.html";
import css from "./app-settings.less";
import { MediaDeviceService } from "@tawenda-npm/aurelia-plugin-live";
let AppSettings = class AppSettings {
    constructor(vchatService, dialogController, mediaDeviceService, defaultLogger) {
        this.vchatService = vchatService;
        this.dialogController = dialogController;
        this.mediaDeviceService = mediaDeviceService;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppSettings`);
    }
    videoInputDeviceChange() {
        this.mediaDeviceService.selectedVideoInputDevice = this.videoInputDevice;
    }
    audioInputDeviceChange() {
        this.mediaDeviceService.selectedAudioInputDevice = this.audioInputDevice;
    }
    get audioInputDevices() {
        this.logger.info(this.mediaDeviceService.audioInputDevices);
        return this.mediaDeviceService.audioInputDevices;
    }
    get videoInputDevices() {
        return this.mediaDeviceService.videoInputDevices;
    }
    canActivate() {
        return this.vchatService.vchat.isHost();
    }
    close() {
        this.dialogController.ok();
    }
};
__decorate([
    watch("videoInputDevice"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppSettings.prototype, "videoInputDeviceChange", null);
__decorate([
    watch("audioInputDevice"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppSettings.prototype, "audioInputDeviceChange", null);
AppSettings = __decorate([
    customElement({ name: "app-settings", template: template, dependencies: [css] }),
    __param(3, ILogger),
    __metadata("design:paramtypes", [VchatService,
        DialogController,
        MediaDeviceService, Object])
], AppSettings);
export default AppSettings;
