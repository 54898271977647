var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { LinksService, MediaDeviceService, ObserverEngine, PresenceNotifierFactory, SfuRegistries, } from "@tawenda-npm/aurelia-plugin-live";
import { inject, ILogger } from "aurelia";
import { Authentication } from "../services/Authentication";
import { VchatApi } from "../services/VchatApi";
import { VchatHost } from "./VchatHost";
import { VchatViewer } from "./VchatViewer";
import { AppState } from "../services/AppState";
import { Sentry } from "../sentry/Sentry";
import { Configuration } from "../services/Configuration";
import { VchatStatistics } from "../services/VchatStatistics";
let VchatFactory = class VchatFactory {
    constructor(authentication, vchatApi, presenceNotifierFactory, mediaDeviceService, linksService, appState, vchatStatistics, sentry, configuration, defaultLogger, observerEngine, sfuRegistries) {
        this.authentication = authentication;
        this.vchatApi = vchatApi;
        this.presenceNotifierFactory = presenceNotifierFactory;
        this.mediaDeviceService = mediaDeviceService;
        this.linksService = linksService;
        this.appState = appState;
        this.vchatStatistics = vchatStatistics;
        this.sentry = sentry;
        this.configuration = configuration;
        this.defaultLogger = defaultLogger;
        this.observerEngine = observerEngine;
        this.sfuRegistries = sfuRegistries;
        this.logger = this.defaultLogger.scopeTo(`vchat`);
    }
    build(vchat) {
        return vchat.host === this.authentication.user
            ? new VchatHost(this.observerEngine, this.vchatApi, this.authentication, this.presenceNotifierFactory, this.logger, this.mediaDeviceService, this.appState, this.linksService, this.sentry, this.sfuRegistries, this.vchatStatistics, vchat)
            : new VchatViewer(this.configuration, this.observerEngine, this.vchatApi, this.authentication, this.presenceNotifierFactory, this.logger, this.appState, this.linksService, this.sentry, vchat);
    }
};
VchatFactory = __decorate([
    inject(),
    __param(9, ILogger),
    __metadata("design:paramtypes", [Authentication,
        VchatApi,
        PresenceNotifierFactory,
        MediaDeviceService,
        LinksService,
        AppState,
        VchatStatistics,
        Sentry,
        Configuration, Object, ObserverEngine,
        SfuRegistries])
], VchatFactory);
export { VchatFactory };
