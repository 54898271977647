var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger, bindable, watch } from "aurelia";
import template from "./app-chat.html";
import css from "./app-chat.less";
import MessagingService from "../../services/MessagingService";
import { VchatService } from "../../services/VchatService";
import { isMobile } from "../../libs/isMobile";
let AppChat = class AppChat {
    constructor(messagingService, vchatService, defaultLogger) {
        this.messagingService = messagingService;
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.isEditorOpen = false;
        this.logger = this.defaultLogger.scopeTo(`AppChat`);
        this.vchat = this.vchatService.vchat;
        this.blurEditorOnSend = isMobile && this.vchat.isViewer();
    }
    get messages() {
        return this.messagingService.messages;
    }
    get isViewer() {
        return this.vchat.isViewer();
    }
    get imBanned() {
        try {
            return this.vchatService.vchat.imBanned();
        }
        catch (e) {
            this.logger.error(e);
            return false;
        }
    }
    get css() {
        return this.vchat.isViewer() ? "app-chat--viewer" : "app-chat--host dashboard-panel";
    }
    openEditor(e) {
        this.isEditorOpen = true;
        e.preventDefault();
        this.messageEditor.focus();
    }
    messageEditorBlur() {
        this.isEditorOpen = false;
    }
    scroll() {
        setTimeout(() => this.messagesList.scroll({ top: this.messagesList.scrollHeight }), 100); // @TODO
    }
    sendMessage(event) {
        this.messagingService.sendMessage(event.detail.value);
        this.input = "";
    }
};
__decorate([
    bindable,
    __metadata("design:type", String)
], AppChat.prototype, "input", void 0);
__decorate([
    watch((chat) => chat.messages.length),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppChat.prototype, "scroll", null);
AppChat = __decorate([
    customElement({ name: "app-chat", template: template, dependencies: [css] }),
    __param(2, ILogger),
    __metadata("design:paramtypes", [MessagingService,
        VchatService, Object])
], AppChat);
export default AppChat;
