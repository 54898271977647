var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { bindable, BindingMode, customElement } from "aurelia";
import template from "./app-navbar.html";
import css from "./app-navbar.less";
let AppNavbar = class AppNavbar {
    constructor() {
        this.tabs = [
            { id: "left", icon: "sliders-h", active: false },
            { id: "center", icon: "envelope", active: true },
            { id: "right", icon: "token", active: false },
        ];
    }
    binding() {
        if (!this.active) {
            this.active = this.tabs[1].id;
        }
        this.refresh();
    }
    refresh() {
        this.tabs.forEach((tab) => (tab.active = tab.id === this.active));
    }
    setActive(tab) {
        this.active = tab.id;
        this.refresh();
    }
};
__decorate([
    bindable({ mode: BindingMode.twoWay }),
    __metadata("design:type", String)
], AppNavbar.prototype, "active", void 0);
AppNavbar = __decorate([
    customElement({ name: "app-navbar", template, dependencies: [css] })
], AppNavbar);
export default AppNavbar;
