var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { LinksService, noop } from "@tawenda-npm/aurelia-plugin-live";
import { customElement, ILogger, watch } from "aurelia";
import template from "./app-stream.html";
import { VchatService } from "../../services/VchatService";
import css from "./app-stream.less";
import { AppState } from "../../services/AppState";
let AppStream = class AppStream {
    constructor(element, linksService, vchatService, appState, defaultLogger) {
        this.element = element;
        this.linksService = linksService;
        this.vchatService = vchatService;
        this.appState = appState;
        this.defaultLogger = defaultLogger;
        this.isActiveSession = true;
        this.orientation = "landscape";
        this.setStream = async () => {
            var _a, _b, _c, _d, _e, _f;
            this.link = this.linksService.watching[0];
            for (const video of this.element.getElementsByTagName("video")) {
                this.video = video;
                if (((_b = (_a = this.link) === null || _a === void 0 ? void 0 : _a.stream) === null || _b === void 0 ? void 0 : _b.id) && ((_d = (_c = this.link) === null || _c === void 0 ? void 0 : _c.stream) === null || _d === void 0 ? void 0 : _d.id) === ((_e = video.srcObject) === null || _e === void 0 ? void 0 : _e.id)) {
                    continue;
                }
                video.srcObject = (_f = this.link) === null || _f === void 0 ? void 0 : _f.stream;
                const playOnClick = () => {
                    video.muted = false;
                    this.appState.muted = false;
                    video.play().catch(noop);
                    document.removeEventListener("click", playOnClick);
                };
                if (video.srcObject) {
                    video.play().catch((err) => {
                        if (err instanceof DOMException && err.name === "NotAllowedError") {
                            video.muted = true;
                            this.appState.muted = true;
                            video.play().catch(noop);
                            document.addEventListener("click", playOnClick);
                        }
                        else {
                            this.logger.error(err);
                        }
                    });
                }
            }
        };
        this.logger = this.defaultLogger.scopeTo(`AppStream`);
        this.vchat = this.vchatService.vchat;
        setInterval(() => this.updateOrientation(), 1000);
    }
    binding() {
        this.init().catch(this.logger.error);
        this.watchActiveSession();
    }
    togglePreviewActive() {
        this.appState.previewActive = !this.appState.previewActive;
    }
    mediaChange() {
        if (this.vchat.isHost()) {
            for (const video of this.element.getElementsByTagName("video")) {
                video.srcObject = this.vchatService.mediaDeviceService.mediaStream;
                video.muted = this.isMuted;
                video.play().catch(noop);
            }
        }
    }
    updateOrientation() {
        var _a, _b;
        this.orientation = ((_a = this.video) === null || _a === void 0 ? void 0 : _a.videoHeight) > ((_b = this.video) === null || _b === void 0 ? void 0 : _b.videoWidth) ? "portrait" : "landscape";
    }
    watchActiveSession() {
        this.isActiveSession = this.vchatService.vchat.isActiveSession();
        if (this.isActiveSession) {
            this.appState.previewActive = true;
        }
        else {
            this.appState.previewActive = this.isViewer;
        }
    }
    async init() {
        await this.vchatService.ready;
        if (!this.vchat.isHost()) {
            this.linksService.on("change", this.setStream);
        }
        await this.linksService.start();
    }
    get isMuted() {
        return this.vchat.isHost() || this.appState.muted;
    }
    get isViewer() {
        return this.vchat.isViewer();
    }
    get css() {
        return [
            this.vchat.isViewer() ? "app-stream--viewer" : "app-stream--host",
            this.appState.previewActive ? "app-stream--preview-active" : "app-stream--preview-inactive",
        ].join(" ");
    }
    mutedChange() {
        for (const video of this.element.getElementsByTagName("video")) {
            video.muted = this.isMuted;
        }
    }
};
__decorate([
    watch((appStream) => appStream.vchatService.mediaDeviceService.mediaStream),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppStream.prototype, "mediaChange", null);
__decorate([
    watch((appStream) => appStream.vchatService.vchat.isActiveSession()),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppStream.prototype, "watchActiveSession", null);
__decorate([
    watch("appState.muted"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppStream.prototype, "mutedChange", null);
AppStream = __decorate([
    customElement({ name: "app-stream", template, dependencies: [css] }),
    __param(4, ILogger),
    __metadata("design:paramtypes", [HTMLElement,
        LinksService,
        VchatService,
        AppState, Object])
], AppStream);
export default AppStream;
