var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { children, customElement, ILogger, watch } from "aurelia";
import template from "./selector.html";
import css from "./selector.less";
let Selector = class Selector {
    constructor(element, defaultLogger) {
        this.element = element;
        this.defaultLogger = defaultLogger;
        this.items = [];
        this.isOpen = true;
        this.hasGroups = false;
        this._selectedGroup = 0;
        this._index = 0;
        this.logger = this.defaultLogger.scopeTo(`Selector`);
        element.validate = this.validate.bind(this);
        element.down = this.down.bind(this);
        element.up = this.up.bind(this);
    }
    set selectedGroup(value) {
        this._selectedGroup = value;
        this.groups.forEach((item, i) => (item.style.display = value === i ? "inherit" : "none"));
    }
    get index() {
        this._index = Math.min(Math.max(this._index, 0), this.items.length - 1);
        return this._index;
    }
    set index(value) {
        this._index = Math.min(Math.max(value, 0), this.items.length - 1);
        this.setClass();
    }
    bound() {
        this.groupsChanged();
    }
    computeActive() {
        var _a, _b;
        this.active = (_b = (_a = this.items) === null || _a === void 0 ? void 0 : _a[this.index]) !== null && _b !== void 0 ? _b : null;
    }
    groupsChanged() {
        var _a;
        this.hasGroups = !!((_a = this.groups) === null || _a === void 0 ? void 0 : _a.length);
        if (this.hasGroups) {
            this.groupsIndex = this.groups.map((group) => group.getElementsByTagName("group-title")[0]);
            this.items = [];
            this.groups.forEach((group) => {
                const items = group.getElementsByTagName("item");
                for (let i = 0, l = items.length; i < l; i++) {
                    this.items.push(items[i]);
                }
            });
            this.selectedGroup = 0;
            this.itemsChanged();
        }
    }
    itemsChanged() {
        this.items.forEach((item) => {
            item.addEventListener("mouseover", () => this.mouseOver(item));
            item.addEventListener("click", () => this.click(item));
        });
        this.setClass();
    }
    validate() {
        const event = new CustomEvent("selected", {
            detail: { item: this.active },
            bubbles: true,
        });
        this.element.dispatchEvent(event);
    }
    down() {
        this.index++;
    }
    up() {
        this.index--;
    }
    click(item) {
        const event = new CustomEvent("selected", {
            detail: { item },
            bubbles: true,
        });
        this.element.dispatchEvent(event);
    }
    mouseOver(item) {
        this.index = this.items.indexOf(item);
    }
    selectGroup(value, event) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        this.selectedGroup = value;
        return false;
    }
    setClass() {
        const active = this.active;
        this.items.forEach((item) => {
            if (active === item) {
                if (!item.classList.contains("active")) {
                    item.setAttribute("active", "");
                }
            }
            else {
                item.removeAttribute("active");
            }
        });
    }
};
__decorate([
    children({
        query: (controller) => controller.host.querySelectorAll("group"),
        filter: () => true,
        map: (node) => node,
    }),
    __metadata("design:type", Array)
], Selector.prototype, "groups", void 0);
__decorate([
    watch("items.length"),
    watch("index"),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Selector.prototype, "computeActive", null);
Selector = __decorate([
    customElement({
        name: "selector",
        template: template,
        dependencies: [css],
    }),
    __param(1, ILogger),
    __metadata("design:paramtypes", [HTMLElement, Object])
], Selector);
export default Selector;
