<template class="dialog app-message-menu">
  <div class="dialog__header">
    <div class="dialog__title">Bannir un utilisateur</div>
  </div>

  <div class="dialog__content app-alert-error__content">
    <div class="dialog__text dialog__text--center">
      Vous allez bannir <user-display id.bind="user"></user-display><br />
      Il ne pourra plus écrire dans le tchat de ce live.
    </div>
    <button class="cta cta--danger" click.delegate="ban()">Bannir</button>
  </div>
</template>
