var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { VchatApi } from "./VchatApi";
import { ILogger } from "aurelia";
import EventEmitter from "events";
const SECONDS = 1000;
let VchatStatistics = class VchatStatistics extends EventEmitter {
    constructor(vchatApi, defaultLogger) {
        super();
        this.vchatApi = vchatApi;
        this._duration = null;
        this._totalViewers = null;
        this._activeViewers = null;
        this._tips = null;
        this._minutes = null;
        this.topTippers = [];
        this.lastTips = [];
        this.usersUpdated = () => {
            const aggregate = {
                active: new Set(),
                users: new Map(),
                minutes: 0,
            };
            this.usersSubscription.data.reduce((res, user) => {
                if (user.status === "open") {
                    res.active.add(user.user);
                }
                if (!res.users.has(user.user)) {
                    res.users.set(user.user, { user: user.user, tips: user.tips });
                }
                else {
                    res.users.get(user.user).tips += user.tips;
                }
                res.minutes += user.minutes;
                return res;
            }, aggregate);
            this._activeViewers = aggregate.active.size;
            this._totalViewers = aggregate.users.size;
            this._minutes = aggregate.minutes;
            this.topTippers = [...aggregate.users.values()]
                .filter((u) => u.tips)
                .sort((a, b) => b.tips - a.tips)
                .slice(0, 10);
        };
        this.statsUpdated = () => {
            this._tips = this.vchatStatsSubscription.data.tips;
            this.lastTips = this.vchatStatsSubscription.data.events
                .reverse()
                .filter((event) => event.type === "tip")
                .slice(0, 15)
                .map((event) => ({ user: event.data.user, amount: event.data.amount, date: new Date(event.date).getTime() }));
            const lastTip = this.lastTips[0];
            if (lastTip !== this.lastTip && Date.now() - this.instanceInitDate > 5 * SECONDS) {
                this.emit("tip");
            }
            this.lastTip = this.lastTips[0];
        };
        this.logger = defaultLogger.scopeTo(`VchatStatistics`);
        this.instanceInitDate = Date.now();
    }
    setupSubscriptions(id) {
        var _a, _b;
        (_a = this.vchatStatsSubscription) === null || _a === void 0 ? void 0 : _a.destroy();
        (_b = this.usersSubscription) === null || _b === void 0 ? void 0 : _b.destroy();
        this.usersSubscription = this.vchatApi.getVchatUsersSubscription(id);
        this.usersSubscription.addListener("change", this.usersUpdated);
        this.vchatStatsSubscription = this.vchatApi.getVchatStatsSubscription(id);
        this.vchatStatsSubscription.addListener("change", this.statsUpdated);
    }
    set vchat(vchat) {
        this.startTs = new Date(vchat.start).getTime();
        this.setupSubscriptions(vchat.id);
        this.updateDuration();
    }
    get duration() {
        return this._duration;
    }
    get minutes() {
        return this._minutes;
    }
    get tips() {
        return this._tips;
    }
    get totalViewers() {
        return this._totalViewers;
    }
    get activeViewers() {
        return this._activeViewers;
    }
    updateDuration() {
        const now = Date.now();
        this._duration = Math.round((now - this.startTs) / 1000);
        setTimeout(() => {
            this.updateDuration();
        }, 1000 - (now % 1000));
    }
};
VchatStatistics = __decorate([
    __param(1, ILogger),
    __metadata("design:paramtypes", [VchatApi, Object])
], VchatStatistics);
export { VchatStatistics };
