<template class="app-goal ${css}">
  <template if.bind="isHost">
    <header class="dashboard-panel__header">
      ${"goal.host_title" | t}
      <div class="dashboard-panel__actions">
        <button click.delegate="addGoal()" class="dashboard-panel__button dashboard-panel__button">
          <i class="icon-edit"></i>
        </button>
        <button click.delegate="deactivateGoal()" class="dashboard-panel__button dashboard-panel__button">
          <i class="icon-trash"></i>
        </button>
      </div>
    </header>
    <template if.bind="goal.active">
      <p class="app-goal__description">${goal.title}</p>
      <progress-bar class="app-goal__progress-bar" progress.bind="goal.progress">
        <div au-slot class="app-goal__progress-bar-content">
          ${goal.value} / ${goal.target} <i class="icon-token app-goal__progress-bar-content-icon"></i>
        </div>
      </progress-bar>
    </template>
    <template else>
      <button click.delegate="addGoal()" class="dashboard-panel__label dashboard-panel__label--empty">
        <span t="goal.not_defined"></span>
        <span class="dashboard-panel__label" t="goal.link">Cliquer ajouter un goal</span>
      </button>
    </template>
  </template>
  <template else>
    <template if.bind="goal.active">
      <span class="app-goal__label" t="goal.viewer_title"></span>
      <p class="app-goal__description">${goal.title}</p>
      <progress-bar class="app-goal__progress-bar" progress.bind="goal.progress">
        <div au-slot class="app-goal__progress-bar-content">
          ${goal.value} / ${goal.target} <i class="icon-token app-goal__progress-bar-content-icon"></i>
        </div>
      </progress-bar>
    </template>
  </template>
</template>
