<template class="app-root ${css}">
  <app-low-wallet-alert if.bind="vchatService.vchat.isViewer()"></app-low-wallet-alert>
  <div class="app-root__content">
    <img class="app-root__logo" src="/static/img/logo.svg" />
    <template if.bind="isLoaded">
      <template if.bind="vchatService.vchat.isViewer()">
        <app-stream></app-stream>
        <app-panels></app-panels>
        <app-tips></app-tips>
      </template>
      <template else>
        <drawer edge="left">
          <app-goal></app-goal>
          <app-widgets></app-widgets>
          <div class="app-root__buttons">
            <button class="cta app-root__settings" click.delegate="settings()" t="settings.button">settings</button>
            <app-close-vchat></app-close-vchat>
          </div>
        </drawer>
        <section id="center">
          <div class="app-root__player">
            <div if.bind="appState.previewActive" class="app-root__player_buttons">
              <app-sound></app-sound>
              <app-switch-camera></app-switch-camera>
            </div>
            <app-stream></app-stream>
          </div>
          <div class="app-root__center">
            <app-chat></app-chat>
            <app-preset-message class="dashboard-panel"> </app-preset-message>
          </div>
        </section>
        <drawer edge="right">
          <app-top-tip class="dashboard-panel"></app-top-tip>
          <app-last-tippers></app-last-tippers>
          <app-top-tippers></app-top-tippers>
        </drawer>
      </template>
    </template>
    <template else>
      <div class="app-root__loader loader">
        <div class="loader__content">
          <div class="loader__circle"></div>
          <div class="loader__circle"></div>
          <div class="loader__circle"></div>
          <div class="loader__shadow"></div>
          <div class="loader__shadow"></div>
          <div class="loader__shadow"></div>
        </div>

        <span>Chargement</span>
      </div>
    </template>
  </div>
  <app-navbar active.bind="activeTab" class="app-root__navbar" if.bind="!vchatService.vchat.isViewer()"></app-navbar>
  <animation-confetti ref="goalAnimation" class="app-root__goal-animation"></animation-confetti>
</template>
