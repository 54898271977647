<template class="dialog app-wallet-error">
  <img class="app-wallet-error__img" src="/static/img/coins.svg" />
  <div class="app-wallet-error__title" t="wallet_error.title">Insufficient tokens</div>
  <p class="app-wallet-error__description" t="wallet_error.description"></p>

  <div class="app-wallet-error__buttons">
    <button
      class="app-wallet-error__button app-wallet-error__button--submit"
      click.delegate="credit()"
      t="wallet_error.credit"
    >
      add money
    </button>
    <button
      class="app-wallet-error__button app-wallet-error__button--cancel"
      click.delegate="close()"
      t="wallet_error.cancel"
    >
      cancel
    </button>
  </div>
</template>
