var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { LinksService } from "@tawenda-npm/aurelia-plugin-live";
import { customElement, ILogger } from "aurelia";
import template from "./app-activate-session.html";
import { VchatService } from "../../services/VchatService";
import css from "./app-activate-session.less";
let AppActivateSession = class AppActivateSession {
    constructor(element, linksService, vchatService, defaultLogger) {
        this.element = element;
        this.linksService = linksService;
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppActivateSession`);
        this.vchat = this.vchatService.vchat;
        this.setActiveSessionText = this.vchat.isHost()
            ? "stream.set_active_host_session_button"
            : "stream.set_active_viewer_session_button";
    }
    setActiveSession() {
        return this.vchatService.vchat.setActiveSession(true);
    }
};
AppActivateSession = __decorate([
    customElement({ name: "app-activate-session", template, dependencies: [css] }),
    __param(3, ILogger),
    __metadata("design:paramtypes", [HTMLElement,
        LinksService,
        VchatService, Object])
], AppActivateSession);
export default AppActivateSession;
