import EventEmitter from "events";
const DEBUG = false;
export var Direction;
(function (Direction) {
    Direction[Direction["Up"] = 1] = "Up";
    Direction[Direction["Down"] = 3] = "Down";
    Direction[Direction["Left"] = 4] = "Left";
    Direction[Direction["Right"] = 2] = "Right";
})(Direction || (Direction = {}));
export class SwipeDetection extends EventEmitter {
    constructor(element) {
        super();
        this.element = element;
        this.isDragged = false;
        this.handleDragTargetDrag = (e) => {
            if (!e.targetTouches) {
                // this is not a touch event
                return;
            }
            if (!this.isDragged) {
                this.startDrag(e);
            }
            this.dragMoveUpdate(e);
        };
        this.handleDragTargetRelease = (e) => {
            if (this.isDragged) {
                this.isDragged = false;
                this.emit("end", {
                    direction: this.direction,
                });
            }
        };
        this.setupEventHandlers();
        if (DEBUG) {
            this.debugSetup();
        }
    }
    debugDrawVector(angle, distance, direction, endDistance) {
        if (!this.canvasContext) {
            return;
        }
        this.canvasContext.fillStyle = "#FFFFFF";
        this.canvasContext.beginPath();
        this.canvasContext.clearRect(0, 0, 100, 100);
        this.canvasContext.fillRect(0, 0, 100, 100);
        this.canvasContext.moveTo(50, 50);
        this.canvasContext.strokeStyle = "#FF0000";
        this.canvasContext.lineTo(Math.cos(angle) * distance * 50 + 50, Math.sin(angle) * distance * 50 + 50);
        this.canvasContext.stroke();
        this.canvasContext.closePath();
        this.canvasContext.beginPath();
        this.canvasContext.moveTo(50, 50);
        this.canvasContext.strokeStyle = "#00FF00";
        if (direction === Direction.Up) {
            this.canvasContext.lineTo(50, -endDistance * 50 + 50);
        }
        else if (direction === Direction.Down) {
            this.canvasContext.lineTo(50, endDistance * 50 + 50);
        }
        else if (direction === Direction.Left) {
            this.canvasContext.lineTo(-endDistance * 50 + 50, 50);
        }
        else if (direction === Direction.Right) {
            this.canvasContext.lineTo(endDistance * 50 + 50, 50);
        }
        this.canvasContext.stroke();
        this.canvasContext.closePath();
    }
    debugSetup() {
        const startPoint = document.createElement("div");
        startPoint.style.backgroundColor = "red";
        startPoint.style.position = "fixed";
        startPoint.style.borderRadius = "10px";
        startPoint.style.height = "10px";
        startPoint.style.width = "10px";
        startPoint.style.zIndex = "1";
        startPoint.style.content = "";
        document.body.appendChild(startPoint);
        this.startPoint = startPoint;
        const canvas = document.createElement("canvas");
        canvas.height = 100;
        canvas.width = 100;
        const context = canvas.getContext("2d");
        document.body.appendChild(canvas);
        canvas.style.position = "fixed";
        canvas.style.top = "10px";
        canvas.style.left = "10px";
        canvas.style.zIndex = "1";
        context.fillStyle = "#FFFFFF";
        context.strokeStyle = "#00FFFF";
        context.fillRect(0, 0, 100, 100);
        this.canvasContext = context;
    }
    setupEventHandlers() {
        this.element.addEventListener("touchmove", this.handleDragTargetDrag);
        this.element.addEventListener("touchend", this.handleDragTargetRelease);
        this.element.addEventListener("click", this.handleDragTargetDrag);
    }
    startDrag(e) {
        this.startPosition = e.targetTouches[0];
        if (this.startPoint) {
            this.startPoint.style.left = this.startPosition.clientX + "px";
            this.startPoint.style.top = this.startPosition.clientY + "px";
        }
        this.isDragged = true;
    }
    vectorLength(a, b) {
        return Math.sqrt(Math.pow(a.clientX - b.clientX, 2) + Math.pow(a.clientY - b.clientY, 2));
    }
    vectorAngle(a, b) {
        return Math.atan2(a.clientY - b.clientY, a.clientX - b.clientX);
    }
    dragMoveUpdate(e) {
        const now = Date.now();
        const position = e.targetTouches[0];
        const length = this.vectorLength(position, this.startPosition);
        const speed = this.lastEventTime
            ? this.vectorLength(this.lastPosition, this.startPosition) / (now - this.lastEventTime)
            : 0;
        const angle = this.vectorAngle(position, this.startPosition);
        const direction = Math.round((angle * 2) / Math.PI) + 2 || 4;
        const distance = Math.min(1, direction === Direction.Down || direction === Direction.Up
            ? (1 / screen.height) * length
            : (1 / screen.width) * length);
        const endLength = length + speed * 4;
        let endDistance = Math.min(1, direction === Direction.Down || direction === Direction.Up
            ? (1 / screen.height) * endLength
            : (1 / screen.width) * endLength);
        if (endDistance > 0.5) {
            endDistance = 1;
            this.direction = direction;
        }
        else {
            this.direction = void 0;
        }
        this.debugDrawVector(angle, distance, direction, endDistance);
        this.lastPosition = position;
        this.lastEventTime = now;
        this.emit("progress", { direction, distance });
    }
}
