import { Vchat } from "./Vchat";
import { HttpError } from "@tawenda-npm/auth-js-browser";
export class VchatHost extends Vchat {
    constructor(observerEngine, vchatApi, authentication, presenceNotifierFactory, logger, mediaDeviceService, appState, linksService, sentry, sfuRegistries, vchatStatistics, vchat) {
        super(observerEngine, vchatApi, authentication, presenceNotifierFactory, linksService, logger, sentry, vchat);
        this.mediaDeviceService = mediaDeviceService;
        this.appState = appState;
        this.sfuRegistries = sfuRegistries;
        this.vchatStatistics = vchatStatistics;
        this.appState.type = "host";
        observerEngine.subscribe(this, "hostSession", () => this.recomputeActiveSession());
        this.vchatStatistics.vchat = this.vchat;
    }
    async init() {
        const superInitPromise = super.init();
        await this.vchatApi.ready;
        await Promise.all([this.setActiveSession(), superInitPromise]);
        this.mediaDeviceService.start();
        this.appState.addListener("mutedChange", ({ value }) => {
            this.mediaDeviceService.audioMuted = value;
        });
        this.appState.muted = false;
    }
    recomputeActiveSession() {
        this._isActiveSession = this.hostSession === this.authentication.session.id;
        this.appState.activeSession = this._isActiveSession;
    }
    pointToEuro(points) {
        return (Math.floor(points * this.share * this.pointValue * 1000) / 1000).toFixed(2);
    }
    async close() {
        const status = this.status;
        try {
            this.status = "closed";
            await this.vchatApi.closeVchat(this.id);
            this.redirectAfterClose("host_close");
        }
        catch (err) {
            this.status = status;
            this.logger.error(err);
            throw err;
        }
    }
    async setActiveSession(force) {
        try {
            await this.vchatApi.setHostSession(this.id, force);
        }
        catch (err) {
            if (err instanceof HttpError && err.response.status === 409) {
                return;
            }
            this.logger.error(err);
            throw err;
        }
    }
    async addGoal(title, target) {
        const { id } = await this.vchatApi.addGoal(this.id, title, target);
        this._goal = id;
        this.goalChanged();
    }
    async deactivateGoal() {
        await this.vchatApi.deactivateGoal(this.id);
        this._goal = void 0;
        this.goalChanged();
    }
}
