var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { sink } from "@aurelia/kernel";
import { Severity } from "@sentry/browser";
import { IContainer } from "aurelia";
import { HttpError } from "@tawenda-npm/auth-js-browser";
import { Sentry } from "./Sentry";
import { Configuration } from "../services/Configuration";
const levelMap = {
    [0 /* trace */]: Severity.Debug,
    [1 /* debug */]: Severity.Debug,
    [2 /* info */]: Severity.Info,
    [3 /* warn */]: Severity.Warning,
    [4 /* error */]: Severity.Error,
    [5 /* fatal */]: Severity.Fatal,
};
let SentrySink = class SentrySink {
    constructor(container) {
        const configuration = container.get(Configuration);
        this.logLevel = configuration.logging.sentry.level;
        this.sentry = container.get(Sentry);
    }
    handleEvent(event) {
        if (event.severity < this.logLevel) {
            return;
        }
        const scope = event.scope[0];
        const rest = [...event.optionalParams] || [];
        const msg = event.message;
        const message = msg instanceof Error ? msg.toString() : msg;
        let error;
        if (msg instanceof Error) {
            error = msg;
        }
        else {
            const index = rest.findIndex((e) => e instanceof Error);
            if (index > -1) {
                error = rest[index];
                rest.splice(index, 1);
            }
        }
        let extras;
        if (rest.length) {
            extras = {};
            rest.forEach((param, index) => {
                extras[index] = param;
            });
        }
        if (error && error instanceof HttpError) {
            extras !== null && extras !== void 0 ? extras : (extras = {});
            extras.httpError = {
                method: error.request.method,
                url: error.request.url,
                authorization: error.request.headers.get("authorization"),
                code: error.response.status,
            };
            if (error.requestBody) {
                extras.httpError["body"] = error.requestBody;
            }
        }
        const log = { scope, message, error, extras };
        this.sentry.logMessage(levelMap[event.severity], log);
    }
};
SentrySink = __decorate([
    sink({
        handles: [0 /* trace */, 1 /* debug */, 2 /* info */, 3 /* warn */, 4 /* error */, 5 /* fatal */],
    }),
    __param(0, IContainer),
    __metadata("design:paramtypes", [Object])
], SentrySink);
export { SentrySink };
