<template
  class="message-editor ${disabled|toClass: 'message-editor--disabled'} ${overflow|toClass: 'message-editor--overflow'} ${!valid|toClass: 'message-editor--invalid'}"
  focusout.delegate="blur($event)"
>
  <div class="message-editor__content">
    <div
      ref="input"
      class="message-editor__input"
      contenteditable="true"
      keydown.delegate="keyDown($event)"
      input.delegate="inputEvent($event)"
      innerhtml.bind="value"
    ></div>

    <div class="message-editor__buttons">
      <button
        class="message-editor__button message-editor__button--emoji"
        click.delegate="emoji($event)"
        t="message_editor.emoji_picker_button"
      ></button>
      <button
        ref="sendButton"
        class="message-editor__button message-editor__button--send"
        disabled.bind="disabled || !valid"
        click.delegate="send($event)"
        t="message_editor.send_button"
      ></button>
    </div>
  </div>

  <selector
    ref="selectorEmoji"
    class="message-editor__emoji-selector emoji-selector"
    if.bind="_selectorEmojiActive"
    selected.delegate="selectedEmoji($event)"
  >
    <template au-slot>
      <group class="emoji-selector__group" repeat.for="category of selectorEmojiList">
        <group-title class="emoji-selector__group-title">${category.char}</group-title>
        <item class="emoji-selector__item" repeat.for="emoji of category.items" value.bind="emoji"> ${emoji[0]} </item>
      </group>
    </template>
  </selector>

  <div if.bind="overflow" class="message-editor__alert">
    <span>${'message_editor.max_size_message' | t : {maxSize}}</span>
  </div>
</template>
