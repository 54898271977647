var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ILogger, inject } from "aurelia";
import { fetch } from "@tawenda-npm/hub";
import { Authentication } from "./Authentication";
import { VchatService } from "./VchatService";
import { Configuration } from "./Configuration";
import { exponentialRetry } from "@tawenda-npm/aurelia-plugin-live";
let UsersRegistryService = class UsersRegistryService {
    constructor(configuration, authentication, vchatService, defaultLogger) {
        this.configuration = configuration;
        this.authentication = authentication;
        this.vchatService = vchatService;
        this.defaultLogger = defaultLogger;
        this.registry = new Map();
        this.retryCount = 0;
        this.logger = this.defaultLogger.scopeTo(`UsersRegistryService`);
    }
    get(id, forceUpdate) {
        let user = this.registry.get(id);
        if (!user || forceUpdate) {
            if (!user) {
                user = { username: "" };
                this.registry.set(id, user);
            }
            fetch(`${this.configuration.urls.vchat}vchats/${this.vchatService.vchat.id}/users/${id}/profile`, {
                headers: {
                    authorization: `Bearer ${this.authentication.sessionTokens.access.toString()}`,
                },
            })
                .then((response) => {
                if (!response.ok) {
                    throw `Can't load user (${response.status})`;
                }
                this.retryCount = 0;
                return response.json();
            })
                .then((data) => {
                Object.assign(user, data);
                user.host = this.vchatService.vchat.host === id;
            })
                .catch((err) => {
                this.logger.error(err);
                setTimeout(() => {
                    this.get(id, true);
                }, exponentialRetry(++this.retryCount));
            });
        }
        return user;
    }
};
UsersRegistryService = __decorate([
    inject(),
    __param(3, ILogger),
    __metadata("design:paramtypes", [Configuration,
        Authentication,
        VchatService, Object])
], UsersRegistryService);
export { UsersRegistryService };
