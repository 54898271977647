import Aurelia, { LoggerConfiguration, RouterConfiguration } from "aurelia";
import { DialogDefaultConfiguration } from "./dialog";
import { LivePluginConfiguration } from "@tawenda-npm/aurelia-plugin-live";
import { Configuration } from "./services/Configuration";
import { Producer as ProducerComponents } from "./components";
import { Producer as ProducerResources } from "./resources";
import { AppInit } from "./app-root";
import { I18nConfiguration } from "@aurelia/i18n";
import HttpApi from "i18next-http-backend";
const au = new Aurelia();
const configuration = Configuration.buildWithHost(window.location.hostname);
const parseSafeJson = (data) => {
    if (!data) {
        return null;
    }
    try {
        return JSON.parse(data);
    }
    catch (e) {
        return null;
    }
};
const windowData = parseSafeJson(window.name);
if (!windowData) {
    sessionStorage.clear();
    window.name = JSON.stringify({
        initialized: true,
    });
}
au.register(LoggerConfiguration.create({
    level: configuration.logging.level,
    sinks: configuration.logging.sinks,
}))
    .register(RouterConfiguration.customize({
    // useUrlFragmentHash: true,
    historyStrategy: "none",
}))
    .register(ProducerComponents)
    .register(ProducerResources)
    .register(DialogDefaultConfiguration)
    .register(I18nConfiguration.customize((options) => {
    options.initOptions = {
        plugins: [HttpApi],
        backend: {
            loadPath: "locales/{{lng}}/{{ns}}.json",
        },
        lng: "fr",
        fallbackLng: "fr",
    };
}))
    .register(LivePluginConfiguration.init(configuration))
    .app({
    component: AppInit,
    host: document.querySelector("app-init"),
});
await au.start();
