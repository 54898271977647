var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import { DialogController } from "../../../dialog";
import { VchatService } from "../../../services/VchatService";
import template from "./app-wallet-error.html";
import css from "./app-wallet-error.less";
let AppWalletError = class AppWalletError {
    constructor(vchatService, dialogController, defaultLogger) {
        this.vchatService = vchatService;
        this.dialogController = dialogController;
        this.defaultLogger = defaultLogger;
        this.logger = this.defaultLogger.scopeTo(`AppWalletError`);
    }
    canActivate() {
        if (this.vchatService.vchat.isViewer()) {
            this.vchat = this.vchatService.vchat;
            return true;
        }
        return false;
    }
    credit() {
        this.vchat.credit();
    }
    close() {
        this.dialogController.ok();
    }
};
AppWalletError = __decorate([
    customElement({ name: "app-wallet-error", template: template, dependencies: [css] }),
    __param(2, ILogger),
    __metadata("design:paramtypes", [VchatService,
        DialogController, Object])
], AppWalletError);
export default AppWalletError;
