<template class="dialog app-alert-error">
  <div class="dialog__header">
    <div class="dialog__title" t="app_alert_error.title">Error</div>
  </div>

  <div class="dialog__content app-alert-error__content">
    <div class="dialog__text" t="app_alert_error.description"></div>
    <button class="cta cta--danger" click.delegate="close()" t="app_alert_error.close">ok</button>
  </div>
</template>
