import { getEmojiMap, list } from "./emojis";
const isEmojiRegexp = new RegExp([]
    .concat(...list.map((category) => category.items.map((emoji) => emoji[0].replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"))))
    .join("|"));
const isEmoji = (str) => {
    if (!str || str.length > 20) {
        return false;
    }
    const char = str.replace(/<emoji>(.*)<\/emoji>/, "$1");
    return !!(Array.from(char).length === 1 && char.match(isEmojiRegexp));
};
const transformToEmoji = (str) => {
    const emojiMap = getEmojiMap();
    return (str || "")
        .split(" ")
        .map((word) => emojiMap[word] || word)
        .join(" ");
};
export { list, isEmoji, transformToEmoji };
