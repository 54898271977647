var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { valueConverter } from '@aurelia/runtime-html';
let ToClassValueConverter = class ToClassValueConverter {
    toView(value, enabled, disabled) {
        enabled = enabled === void 0 || enabled === null ? "enabled" : enabled;
        disabled = disabled === void 0 || disabled === null ? "" : disabled;
        return value ? enabled : disabled;
    }
};
ToClassValueConverter = __decorate([
    valueConverter('toClass')
], ToClassValueConverter);
export { ToClassValueConverter };
