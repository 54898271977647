var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ILogger, inject } from "aurelia";
import { MessagingServiceFactory, } from "@tawenda-npm/aurelia-plugin-live";
import { VchatService } from "./VchatService";
import { Configuration } from "./Configuration";
const MAX_MESSAGES = 500;
let MessagingService = class MessagingService {
    constructor(messagingServiceFactory, vchatService, configuration, defaultLogger) {
        this.messagingServiceFactory = messagingServiceFactory;
        this.vchatService = vchatService;
        this.configuration = configuration;
        this.defaultLogger = defaultLogger;
        this.messages = [];
        this.lastTips = [];
        this.logger = this.defaultLogger.scopeTo(`MessagingService`);
        this.ready = this.init();
    }
    binding() {
        this.init().catch(this.logger.error);
    }
    async init() {
        await this.vchatService.ready;
        this.messagingService = this.messagingServiceFactory.build({
            service: this.configuration.messaging.service,
        });
        await this.messagingService.start();
        await this.messagingService.waitChannel(this.vchatService.vchat.channel);
        this.channel = this.messagingService.getChannel(this.vchatService.vchat.channel);
        await this.initMessages();
    }
    async initMessages() {
        await this.channel.fetchMessages();
        this.channel.messages.reverse().map((message) => this.messages.push(message));
        this.channel.messagesSubscription.on("insert", (message) => {
            this.messages.unshift(message);
            this.messages.splice(MAX_MESSAGES);
        });
        this.messages.splice(MAX_MESSAGES);
    }
    sendMessage(data, type = void 0) {
        this.channel.sendMessage(data, type).catch(this.logger.error);
    }
};
MessagingService = __decorate([
    inject(),
    __param(3, ILogger),
    __metadata("design:paramtypes", [MessagingServiceFactory,
        VchatService,
        Configuration, Object])
], MessagingService);
export default MessagingService;
