var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject, ILogger } from "aurelia";
import { VchatService } from "./VchatService";
import { ObserverEngine } from "@tawenda-npm/aurelia-plugin-live";
let GoalService = class GoalService {
    constructor(vchatService, observerEngine, defaultLogger) {
        this.vchatService = vchatService;
        this.observerEngine = observerEngine;
        this.defaultLogger = defaultLogger;
        this.progress = 0;
        this.onCompleteCallbacks = [];
        this.completed = false;
        this.logger = this.defaultLogger.scopeTo(`UsersRegistryService`);
        this.vchatService.ready.then(() => {
            observerEngine.subscribe(this.vchatService.vchat, "goal", () => this.changeGoal());
            this.changeGoal();
        });
    }
    set onComplete(callback) {
        this.onCompleteCallbacks.push(callback);
    }
    computeProgress() {
        const progress = this.data ? this.data.value / this.data.target : 0;
        if (progress === this.progress) {
            return;
        }
        this.progress = progress;
        if (this.progress >= 1) {
            this.onCompleteCallbacks.forEach((clb) => clb(!this.completed));
            this.completed = true;
        }
        else {
            this.completed = false;
        }
    }
    changeGoal() {
        var _a, _b;
        this.vchatService.vchat.goal;
        this.data = this.vchatService.vchat.goal;
        this.computeProgress();
        (_a = this.observeTarget) === null || _a === void 0 ? void 0 : _a.call(this);
        (_b = this.observeValue) === null || _b === void 0 ? void 0 : _b.call(this);
        if (this.data) {
            this.observeTarget = this.observerEngine.subscribe(this.data, "target", () => this.computeProgress());
            this.observeValue = this.observerEngine.subscribe(this.data, "value", () => this.computeProgress());
        }
    }
    get title() {
        var _a;
        return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.title) || "";
    }
    get active() {
        return !!this.data;
    }
    get value() {
        var _a;
        return Math.min(((_a = this.data) === null || _a === void 0 ? void 0 : _a.value) || 0, this.target);
    }
    get target() {
        var _a;
        return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.target) || 0;
    }
};
GoalService = __decorate([
    inject(),
    __param(2, ILogger),
    __metadata("design:paramtypes", [VchatService,
        ObserverEngine, Object])
], GoalService);
export { GoalService };
