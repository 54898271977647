var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, bindable } from "aurelia";
import template from "./user-display.html";
import css from "./user-display.less";
import { UsersRegistryService } from "../../../services/UsersRegistryService";
let UserDisplay = class UserDisplay {
    constructor(usersRegistryService) {
        this.usersRegistryService = usersRegistryService;
    }
    get user() {
        return this.usersRegistryService.get(this.id);
    }
    get css() {
        return this.user.host ? "user-display--host" : this.user.fan ? "user-display--fan" : "";
    }
};
__decorate([
    bindable,
    __metadata("design:type", String)
], UserDisplay.prototype, "id", void 0);
UserDisplay = __decorate([
    customElement({
        name: "user-display",
        template: template,
        dependencies: [css],
    }),
    __metadata("design:paramtypes", [UsersRegistryService])
], UserDisplay);
export default UserDisplay;
