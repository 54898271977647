import UserDisplay from "./elements/user-display";
import ProgressBar from "./elements/progress-bar";
import Drawer from "./elements/drawer";
import MessageEditor from "./elements/message-editor";
import Selector from "./elements/selector";
import { TimeValueConverter } from "./value-converters/time";
import { ToClassValueConverter } from "./value-converters/to-class";
import ToggleButton from "./elements/toggle-button";
import { AnimationConfetti } from "./elements/animation-confetti";
import PresetMessage from "./elements/preset-message";
export const Producer = {
    register: (container) => {
        container.register(UserDisplay, ProgressBar, TimeValueConverter, ToClassValueConverter, Drawer, MessageEditor, Selector, ToggleButton, AnimationConfetti, PresetMessage);
    },
};
