import Ease from "./Ease";
import cubeBezier from "./cubeBezier";
import { HALF_PI, TIME_STEP } from "./constants";
export class Particle {
    constructor(p0, p1, p2, p3) {
        this.p0 = p0;
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.time = 0;
        this.duration = 3 + Math.random() * 2;
        this.color = "#" + Math.floor(Math.random() * 0xffffff).toString(16);
        this.w = 8;
        this.h = 6;
        this.complete = false;
    }
    update() {
        if (this.complete) {
            return;
        }
        this.time = Math.min(this.duration, this.time + TIME_STEP);
        const f = Ease.outCubic(this.time, 0, 1, this.duration);
        const p = cubeBezier(this.p0, this.p1, this.p2, this.p3, f);
        const dx = p.x - this.x;
        const dy = p.y - this.y;
        this.r = Math.atan2(dy, dx) + HALF_PI;
        this.sy = Math.sin(Math.PI * f * 10);
        this.x = p.x;
        this.y = p.y;
        this.complete = this.time === this.duration;
    }
    draw(context) {
        if (this.complete) {
            return;
        }
        context.save();
        context.translate(this.x, this.y);
        context.rotate(this.r);
        context.scale(1, this.sy);
        context.fillStyle = this.color;
        context.fillRect(-this.w * 0.5, -this.h * 0.5, this.w, this.h);
        context.restore();
    }
}
