var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { IContainer, inject } from "aurelia";
import { init, configureScope, addBreadcrumb, withScope, captureException, captureMessage, Severity, } from "@sentry/browser";
import { Configuration } from "../services/Configuration";
import { FetchError } from "@tawenda-npm/hub";
const SECONDS = 1000;
const MAX_AVAILABLE_REQUESTS = 20;
let Sentry = class Sentry {
    constructor(container) {
        var _a;
        this.availableRequests = MAX_AVAILABLE_REQUESTS;
        this.beforeSend = (event, hint) => {
            var _a, _b, _c, _d, _e;
            if (this.availableRequests) {
                this.availableRequests--;
                const exception = hint.originalException;
                if (exception instanceof FetchError) {
                    const path = ((_a = exception.url) === null || _a === void 0 ? void 0 : _a.hostname) +
                        ((_d = (_c = (_b = exception.url) === null || _b === void 0 ? void 0 : _b.pathname) === null || _c === void 0 ? void 0 : _c.replace) === null || _d === void 0 ? void 0 : _d.call(_c, /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}){1}/g, "{{uuid}}"));
                    event.fingerprint = [(_e = exception.url) === null || _e === void 0 ? void 0 : _e.hostname, exception.method, path, exception.code];
                }
                return event;
            }
            return null;
        };
        const configuration = container.get(Configuration);
        init({
            dsn: (_a = configuration.logging.sentry) === null || _a === void 0 ? void 0 : _a.dsn,
            release: `${configuration.version.tag}+sha.${configuration.version.sha.substr(0, 8)}`,
            beforeSend: this.beforeSend,
            autoSessionTracking: false,
        });
        setInterval(() => (this.availableRequests = Math.min(MAX_AVAILABLE_REQUESTS, this.availableRequests + 5)), 30 * SECONDS);
    }
    set vchat(vchat) {
        this._vchat = vchat;
    }
    setUserContext(data) {
        configureScope((scope) => {
            scope.setUser(data);
        });
    }
    addBreadCrumb(data) {
        addBreadcrumb({
            category: data.category,
            message: data.message,
            level: Severity.Info,
        });
    }
    logMessage(severity, log) {
        withScope((scope) => {
            scope.setLevel(severity);
            if (log.extras) {
                scope.setExtras(log.extras);
            }
            if (log.scope) {
                scope.setTag("logger", log.scope);
            }
            if (this._vchat) {
                scope.setContext("vchat", this._vchat.toJSON());
            }
            if (log.error) {
                captureException(log.error);
            }
            else {
                captureMessage(log.message);
            }
        });
    }
};
Sentry = __decorate([
    inject(),
    __param(0, IContainer),
    __metadata("design:paramtypes", [Object])
], Sentry);
export { Sentry };
