var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { customElement, ILogger } from "aurelia";
import template from "./app-preset-message.html";
import css from "./app-preset-message.less";
import MessagingService from "../../services/MessagingService";
import { VchatService } from "../../services/VchatService";
import { UserPreferences } from "../../services/UserPreferences";
import { DialogService, IDialogService } from "../../dialog";
import AppPresetMessageItem from "./app-preset-message-item";
let AppPresetMessage = class AppPresetMessage {
    constructor(messagingService, vchatService, userPreferences, dialogService, defaultLogger) {
        this.messagingService = messagingService;
        this.vchatService = vchatService;
        this.userPreferences = userPreferences;
        this.dialogService = dialogService;
        this.defaultLogger = defaultLogger;
        this.edit = false;
        this.input = "";
        this.logger = this.defaultLogger.scopeTo(`AppChat`);
    }
    get raw() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.userPreferences) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b["preset-message"]) !== null && _c !== void 0 ? _c : "";
    }
    async addItem() {
        const { controller } = await this.dialogService.open({
            component: () => AppPresetMessageItem,
            keyboard: "Escape",
            overlayDismiss: true,
        });
        const response = await controller.closed;
        if (response.status === "ok" /* Ok */) {
            const value = response.value;
            if (value.title && value.amount) {
                this.input = this.input.trim();
                this.input += `\n${value.amount}-- ${value.title}`;
            }
        }
    }
    send() {
        this.messagingService.sendMessage(this.raw, "preset");
    }
    startEdit() {
        this.input = this.raw;
        this.edit = true;
    }
    stopEdit() {
        this.userPreferences.setPreference("preset-message", this.input).catch(this.logger.error);
        this.edit = false;
    }
    cancelEdit() {
        this.edit = false;
    }
};
AppPresetMessage = __decorate([
    customElement({ name: "app-preset-message", template, dependencies: [css] }),
    __param(3, IDialogService),
    __param(4, ILogger),
    __metadata("design:paramtypes", [MessagingService,
        VchatService,
        UserPreferences,
        DialogService, Object])
], AppPresetMessage);
export default AppPresetMessage;
